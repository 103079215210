import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { Integrations } from '../integrations';
import { EntityType } from '@sidkik/global';

export interface UserRequestData {
  displayName?: string;
  email?: string;
  roles?: string[];
  level?: number;
  status: string;
  isInvite?: boolean;
}

export interface UserRequestProperties extends StorageProperties {
  data: UserRequestData;
  meta: Meta;
  id: string;
  integrations: Integrations;
  tenant?: string;
}

export class UserRequest extends Storage implements UserRequestProperties {
  public override data!: UserRequestData;
  constructor(options?: UserRequestProperties, user?: string) {
    super(options, user, EntityType.UserRequest);
    this.update(options?.data);
  }

  public update(data?: UserRequestData): void {
    this.data = { ...data } as unknown as UserRequestData;
  }
}

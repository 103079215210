import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MailerConfig,
  AppConfig,
  APP_CONFIG,
  TraceService,
  HTTPTraceHeader,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface MailerAPI {
  getConfig(): Observable<MailerConfig>;
  updateConfig(config: MailerConfig): Observable<MailerConfig>;
}

@Injectable({
  providedIn: 'root',
})
export class MailerService implements MailerAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  getConfig(trace?: HTTPTraceHeader): Observable<MailerConfig> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminMailerGetConfig);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminMailerGetConfig
      );
      internalTrace = true;
    }
    return this.http
      .get<MailerConfig>(
        `${this.tenantConfig.api.endpoint}/admin/mailer/config`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminMailerGetConfig, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminMailerGetConfig);
        })
      );
  }

  updateConfig(
    config: MailerConfig,
    trace?: HTTPTraceHeader
  ): Observable<MailerConfig> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminMailerUpdateConfig);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminMailerUpdateConfig
      );
      internalTrace = true;
    }
    return this.http
      .put<MailerConfig>(
        `${this.tenantConfig.api.endpoint}/admin/mailer/config`,
        { ...config },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminMailerUpdateConfig, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminMailerUpdateConfig);
        })
      );
  }

  sendTestMail(
    email: string,
    customerId: string,
    templateId: string,
    trace?: HTTPTraceHeader
  ): Observable<{
    customerId: string;
    email: string;
    id: string;
    templateId: string;
  }> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminSendTestEmail);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminSendTestEmail
      );
      internalTrace = true;
    }

    return this.http
      .post<{
        email: string;
        customerId: string;
        templateId: string;
        id: string;
      }>(
        `${this.tenantConfig.api.endpoint}/admin/mailer/test`,
        { email, customerId, templateId },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSendTestEmail, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSendTestEmail);
        })
      );
  }
}

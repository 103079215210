import { ProductType, ProductSubType } from './product-type.enum';
import { StorageProperties, Storage } from '../storage';
import { Meta } from '../meta';
import { Asset } from '../library';
import { Integrations } from '../integrations';
import { EntityType } from '@sidkik/global';
import { BookingPhysicalLocation } from '../scheduling';

export enum ChargingStrategy {
  UpFront = 'upfront',
  WithinCancellationPeriod = 'withinCancellationPeriod',
  PostDelivery = 'postDelivery',
}

export interface ProductData {
  name: string;
  type: ProductType; // service or good
  active: boolean;
  listed?: boolean;
  attributes?: any;
  caption: string;
  description: string;
  license?: string;
  metadata?: {
    numberOfSections?: string;
    skillLevel?: string;
    timeToComplete?: string;
  };
  tags?: string[];
  shippable: boolean;
  descriptor?: string; // only set if service
  unitLabel?: string;
  image?: Asset;
  video?: Asset;
  url: string;
  duration?: number; // for session
  linkedCourses?: string[];
  linkedChallenges?: string[];
  linkedMemberships?: string[];
  linkedPlatforms?: string[];
  linkedPlatformTrials?: string[];
  linkedSessions?: string[];
  whatIsIncluded?: string;
  subtype: ProductSubType; // challenge, course ...
  chargingStrategy?: ChargingStrategy;
  cancellationPeriodHours?: number;
  quantity?: number;
  upsell?: {
    enabled: boolean;
    products: string[];
    promoCode?: string;
    header: string;
    content: string;
  };
  bookingVirtualLocationVendor?: string;
  bookingPhysicalLocationId?: string;
}

export interface ProductProperties extends StorageProperties {
  data: ProductData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

export class Product extends Storage implements ProductProperties {
  public override data!: ProductData;

  constructor(options?: ProductProperties, user?: string) {
    super(options, user, EntityType.Product);
    this.syncChangeFields = [
      'name',
      'active',
      'shippable',
      'description',
      'url',
      'caption',
    ];
    this.update(options?.data);
  }

  public update(data?: ProductData, trackChanges = false): void {
    if (trackChanges) {
      super.checkIfSync(data);
    }

    this.data = { ...data } as unknown as ProductData;

    const {
      active = true,
      listed = true,
      attributes = {},
      shippable = false,
      linkedCourses = [],
      linkedChallenges = [],
      linkedMemberships = [],
      linkedPlatforms = [],
      linkedPlatformTrials = [],
      linkedSessions = [],
      subtype = ProductSubType.Course,
    } = data || {};
    this.data.active = active;
    this.data.listed = listed;
    this.data.attributes = attributes;
    this.data.shippable = shippable;
    this.data.linkedCourses = linkedCourses;
    this.data.linkedChallenges = linkedChallenges;
    this.data.linkedMemberships = linkedMemberships;
    this.data.linkedPlatforms = linkedPlatforms;
    this.data.linkedPlatformTrials = linkedPlatformTrials;
    this.data.linkedSessions = linkedSessions;
    this.data.type = ProductType.GOOD;
    this.data.subtype = subtype;
  }
}

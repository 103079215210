<ng-template>
  <div
    class="tw-w-full tw-h-full tw-bg-white tw-border-2 tw-py-4 tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-overflow-y-auto tw-rounded-sm">
    <div class="tw-mx-auto tw-mb-4">
      <h1 class="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900">Media</h1>
      <!-- <p class="tw-mt-4 tw-max-w-xl tw-text-sm tw-text-gray-700">Select your media asset from the list below.</p> -->
    </div>
    <section aria-labelledby="filter-heading" class="tw-mb-4">
      <h2 id="filter-heading" class="tw-sr-only">Filters</h2>
      <div>
        <label for="filterName" class="input-label">Search media</label>
        <div class="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
          <div class="tw-relative tw-flex tw-flex-grow tw-items-stretch tw-focus-within:tw-z-10">
            <input type="filterName" name="filterName" id="filterName" class="input-with-suffix"
              (input)="inputFilterRequest($event)" [value]="visibleFilter" placeholder="photo1.jpg">
          </div>
          <button type="button" class="input-suffix-btn">
            <!-- Heroicon name: mini/bars-arrow-up -->
            <svg class="tw-h-5 tw-w-5 tw-text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z"
                clip-rule="evenodd" />
            </svg>
            <span>Sort</span>
          </button>
        </div>
      </div>
    </section>
    <ul role="list"
      class="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-8 sm:tw-grid-cols-3 sm:tw-gap-x-6 lg:tw-grid-cols-6 xl:tw-gap-x-8">
      <li class="tw-relative" *ngFor="let asset of filteredAssets$ | async">
        <div (click)="select(asset)"
          class="tw-group tw-aspect-w-10 tw-aspect-h-7 tw-block tw-w-full tw-overflow-hidden tw-rounded-lg tw-bg-gray-100 tw-focus-within:tw-ring-2 tw-focus-within:tw-ring-indigo-500 tw-focus-within:tw-ring-offset-2 tw-focus-within:tw-ring-offset-gray-100">
          <!-- <img [src]="getThumbnail(asset)" alt="" class="tw-pointer-events-none tw-object-cover group-hover:tw-opacity-75"> -->
          <sidkik-asset [class]="'tw-h-full tw-w-full tw-rounded-sm tw-block tw-pointer-events-none tw-object-cover'"
            [thumbOnly]="true" [asset]="asset" preset="asset_picker_grid"></sidkik-asset>
          <button type="button" class="tw-absolute tw-inset-0 focus:tw-outline-none">
            <span class="tw-sr-only">{{asset.data.name}}</span>
          </button>
        </div>
        <p class="tw-pointer-events-none tw-mt-2 tw-block tw-truncate tw-text-sm tw-font-medium tw-text-gray-900">
          {{asset.data.name}}</p>
        <p class="tw-pointer-events-none tw-block tw-text-sm tw-font-medium tw-text-gray-500">{{asset.data.size |
          fileSize}}</p>
      </li>
    </ul>
  </div>
</ng-template>
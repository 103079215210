import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayoutFacade } from '../../+state/layout.facade';

@Component({
  selector: 'sidkik-user-info',
  template: ` <sidkik-pure-user-info
    [userName]="userName$ | async"
    [userPhoto]="userPhoto$ | async"
    [userEmail]="userEmail$ | async"
  ></sidkik-pure-user-info>`,
})
export class UserInfoComponent implements OnInit {
  userName$!: Observable<string>;
  userPhoto$!: Observable<string>;
  userEmail$!: Observable<string>;
  constructor(private layoutFacade: LayoutFacade) {}

  ngOnInit(): void {
    this.userName$ = this.layoutFacade.user$.pipe(
      map((user) => user.displayName ?? '')
    );
    this.userPhoto$ = this.layoutFacade.user$.pipe(
      map((user) => user.providerData[0]?.photoURL ?? '')
    );
    this.userEmail$ = this.layoutFacade.user$.pipe(
      map((user) => user.email ?? '')
    );
  }
}

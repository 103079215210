import { Section } from '.';
import { Instructor } from '.';
import { AssetProperties } from '../library';
import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface CourseData {
  title: string;
  image?: AssetProperties;
  video?: AssetProperties;
  tags?: string[];
  synopsis: string;
  description?: string;
  sections: Section[];
  instructor?: Instructor;
}

export interface CourseProperties extends StorageProperties {
  data: CourseData;
  meta: Meta;
  id: string;
}

export class Course extends Storage implements CourseProperties {
  public override data!: CourseData;

  constructor(options?: CourseProperties, user?: string) {
    super(options, user, EntityType.Course);
    this.update(options?.data);
  }

  public update(data?: CourseData): void {
    const {
      title = '',
      image,
      video,
      tags = [],
      synopsis = '',
      description = '',
      sections = [],
      instructor = {},
    } = data || {};
    this.data.title = title;
    this.data.image = image;
    this.data.video = video;
    this.data.tags = tags;
    this.data.synopsis = synopsis;
    this.data.description = description;
    this.data.sections = sections;
    this.data.instructor = instructor;
  }
}

import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface ActiveCampaignConfigData {
  id: string;
  eCommerceId: string;
  apiToken: string;
  active: boolean;
}

export interface ActiveCampaignConfigProperties extends StorageProperties {
  data: ActiveCampaignConfigData;
  meta: Meta;
  id: string;
}

export class ActiveCampaignConfig
  extends Storage
  implements ActiveCampaignConfigProperties
{
  public override data!: ActiveCampaignConfigData;

  constructor(options?: ActiveCampaignConfigProperties, user?: string) {
    super(options, user, EntityType.ActiveCampaignConfiguration);
    this.update(options?.data);
  }

  public update(data?: ActiveCampaignConfigData): void {
    this.data = { ...data } as unknown as ActiveCampaignConfigData;
  }
}

export const loadGoogleConfigType = '[user-profile/google/config] load config';
export const loadGoogleConfigSuccessType =
  '[user-profile/google/config] load config success';
export const loadGoogleConfigFailureType =
  '[user-profile/google/config] load config failure';

export const updateGoogleConfigType = '[user-profile/google/config] update config';
export const updateGoogleConfigSuccessType =
  '[user-profile/google/config] update config success';
export const updateGoogleConfigFailureType =
  '[user-profile/google/config] update config failure';

export const setGoogleNotConnectedType = '[user-profile/google] set not connected';
export const setGoogleConnectedType = '[user-profile/google] set connected';
export const connectionForGoogleEstablished =
  '[user-profile/google] connection established';
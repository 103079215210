import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface QuickbooksConfigData {
  accounts: {
    incomeAccount?: number;
    productRevenueRecognitionAccount?: number;
    refundToAccount?: number;
    royaltyPaymentCOGS?: number;
    royaltyPaymentsLiability?: number;
    stripeAccount?: number;
    stripeFeesPaymentCOGS?: number;
    stripeProcessingFeesLiability?: number;
    stripeVendorAccount?: number;
    undepositedFunds?: number;
    affilitateFeesCOGS?: number;
    affilitateFeesLiability?: number;
    tenantAccount?: number;
  };
  connected: boolean;
}

export interface QuickbooksConfigProperties extends StorageProperties {
  data: QuickbooksConfigData;
  meta: Meta;
  id: string;
}

export class QuickbooksConfig
  extends Storage
  implements QuickbooksConfigProperties
{
  public override data!: QuickbooksConfigData;

  constructor(options?: QuickbooksConfigProperties, user?: string) {
    super(options, user, EntityType.QuickbooksConfiguration);
    this.update(options?.data);
  }

  public update(data?: QuickbooksConfigData): void {
    const { accounts = {}, connected = false } = data || {};
    this.data.accounts = accounts;
    this.data.connected = connected;
  }
}

import { Observable, throwError, of } from 'rxjs';
import { retryWhen, mergeMap, delay } from 'rxjs/operators';

const getErrorMessage = (maxRetry: number) =>
  `Attempted ${maxRetry} times. Giving up.`;
const defaultMaxRetries = 5;
const defaultBackoff = 1000;

export function retryWithBackoff(
  delayMs: number,
  maxRetry: number = defaultMaxRetries,
  backOffMs: number = defaultBackoff
) {
  let retries = maxRetry;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          mergeMap((error) => {
            if (retries-- > 0) {
              const backOffTime = delayMs + (maxRetry - retries) * backOffMs;
              return of(error).pipe(delay(backOffTime));
            }
            return throwError({
              message: getErrorMessage(maxRetry),
              responseError: error,
            });
          })
        )
      )
    );
}

import { Asset, AssetProperties } from '../library';
import { StorageProperties, Storage } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface SectionData {
  content: string;
  course: string;
  title: string;
  image?: AssetProperties;
  video?: AssetProperties;
  accessibleOn?: any;
  sections?: SectionProperties[];
  trackProgress?: boolean;
  published?: boolean;
  wordCount?: number;
  videoTime?: number;
}

export interface SectionProperties extends StorageProperties {
  data: SectionData;
  meta: Meta;
  id: string;
}

export class Section extends Storage implements SectionProperties {
  public override data!: SectionData;

  constructor(options?: SectionProperties, user?: string) {
    super(options, user, EntityType.Section);
    this.update(options?.data);
  }

  public update(data?: SectionData): void {
    this.data = { ...data } as unknown as SectionData;
    this.data.sections = this.data.sections || [];
  }
}

import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface ContentData {
  course?: string;
  challenge?: string;
  content: string;
  description: string;
  name: string;
  wordCount: number;
}

export interface ContentProperties extends StorageProperties {
  data: ContentData;
  meta: Meta;
  id: string;
}

export class Content extends Storage implements ContentProperties {
  public override data!: ContentData;

  constructor(options?: ContentProperties, user?: string) {
    super(options, user, EntityType.Content);
    this.update(options?.data);
  }

  public update(data?: ContentData): void {
    this.data = { ...data } as unknown as ContentData;
  }
}

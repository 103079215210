import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { FormLayout } from './form-layout';
import { EntityType } from '@sidkik/global';

export interface FormData {
  title: string;
  content?: FormLayout;
  personalizations?: string[];
  isDoubleOptIn?: boolean;
  doubleOptInEmailId?: string;
  successMessage?: string;
  successPage?: string;
}

export interface FormProperties extends StorageProperties {
  data: FormData;
  meta: Meta;
  id: string;
}

export class Form extends Storage implements FormProperties {
  public override data!: FormData;

  constructor(options?: FormProperties, user?: string) {
    super(options, user, EntityType.Form);
    this.update(options?.data);
  }

  public update(data?: FormData): void {
    this.data = { ...data } as unknown as FormData;
    this.data.content = this.data.content || {};
  }
}

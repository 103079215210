<div sidkikIntersection [threshold]="1" rootMargin="-44px 0px 0px 0px" class="page-header-bottom-half">
  <div class="page-header-bottom-container">
    <div class="page-header-bottom-title-container">
      <h2 class="page-header-bottom-title-h2">Help
      </h2>
    </div>
  </div>
</div>

<div class="page-content">
  <div style="position: relative; padding-bottom: calc(71.50663449939685% + 88px); height: 0;"><iframe
      src="https://app.supademo.com/showcase/embed/clr5ejfxi30hmper3zmzc7yek" frameborder="0"
      webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
</div>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import {
  Observable,
  catchError,
  shareReplay,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import { retryWithBackoff } from '../operators/retry-with-backoff.operator';
import {
  CartProperties,
  CouponProperties,
  PromoCodeProperties,
} from '@sidkik/db';

export interface ShopAPI {
  checkCoupon(
    code: string,
    cart: CartProperties,
    trace?: HTTPTraceHeader
  ): Observable<{
    valid: boolean;
    code?: PromoCodeProperties;
    coupon?: CouponProperties;
    discountedCart?: CartProperties;
    issue?: string;
    requiresFuturePaymentMethod?: boolean;
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class ShopService implements ShopAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  checkCoupon(
    code: string,
    cart: CartProperties,
    trace?: HTTPTraceHeader
  ): Observable<{
    valid: boolean;
    code?: PromoCodeProperties;
    coupon?: CouponProperties;
    discountedCart?: CartProperties;
    issue?: string;
    requiresFuturePaymentMethod?: boolean;
  }> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.shopCheckPromoCode);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.shopCheckPromoCode
      );
      internalTrace = true;
    }
    return this.http
      .post<{
        valid: boolean;
        code?: PromoCodeProperties;
        coupon?: CouponProperties;
      }>(
        `${this.tenantConfig.api.endpoint}/shop/promo/check`,
        { code, cart },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        timeout(this.timeoutMs),
        retryWithBackoff(
          this.retryConfig.retryDelayMs,
          this.retryConfig.retries,
          this.retryConfig.backoffMs
        ),
        catchError((err) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.shopCheckPromoCode, err);
          err.details = { function: 'getCoupon' };
          return throwError(() => err);
        }),
        shareReplay(1),
        tap(
          () =>
            internalTrace &&
            this.traceService.endSpan(SpanTypes.shopCheckPromoCode)
        )
      );
  }
}

import { EntityType } from '../constants';

export enum WorkflowStepImplementation {
  Trigger = 'TRIGGER',
  Root = 'ROOT',
  Activity = 'ACTIVITY',
  Switch = 'SWITCH',
  Branch = 'BRANCH',
  Wait = 'WAIT',
}

export interface Criteria {
  target: string;
  logicalOperator: string;
  conditions: Condition[];
}

export enum LogicalOperator {
  And = 'And',
  Or = 'Or',
}

export enum ActivityType {
  SlackMessage = 'slack_message',
  SendEmail = 'send_email',
  ProvisionPlatformTenant = 'provision_platform_tenant',
  ProvisionPlatformTenantTrial = 'provision_platform_tenant_trial',
  AddTag = 'add_tag',
  RemoveTag = 'remove_tag',
  AddList = 'add_list',
  RemoveList = 'remove_list',
  CancelTrialWait = 'cancel_trial_wait',
  ConvertTrialToPaid = 'convert_trial_to_paid',
}

export enum WaitType {
  Wait = 'wait',
}

export enum RootType {
  Go = 'go',
}

export enum TriggerType {
  Parent = 'parent',
  PlaceHolder = 'placeholder',
  Event = 'event',
}

export enum SwitchType {
  Multiple = 'multiple',
  Binary = 'binary',
}

export enum SwitchCase {
  Case = 'case',
  Default = 'default',
}

export interface Condition {
  target: string;
  filter?: string;
  property?: string;
  operator: string;
  value: any;
}

export interface ConditionDefinition {
  name: string;
  group: string;
  target: string;
  filterBy?: FilterDefinition;
  availableOperators?: Operator[];
  valueType: string;
  platformOnly?: boolean;
  applicableConditionals?: string[];
  dynamicFilterBy?: string;
}

export interface Operator {
  value: string;
  label: string;
}

export interface FilterDefinition {
  name: string;
  target: string;
  operator: string;
  valueType?: string;
}

export enum OperatorLiterals {
  True = 'true',
  False = 'false',
  StringExists = 'stringexists',
  StringDoesNotExist = 'stringdoesnotexist',
}

export interface EventDefinition {
  name?: string;
  target: string;
  entity: string;
  instance?: string; // instance of the entity
  unfiltered?: boolean;
  platformOnly?: boolean;
}

export enum FilterNames {
  Course = 'Course',
  Challenge = 'Challenge',
  Email = 'Email',
  Campaign = 'Campaign',
  CustomerTag = 'CustomerTag',
  List = 'CustomerList',
  Product = 'Product',
  Form = 'Form',
  FormCheckBox = 'FormCheckBox',
}

export const Filters: Record<string, FilterDefinition> = {
  [FilterNames.Course]: {
    name: FilterNames.Course,
    target: 'courseId',
    operator: '',
    valueType: 'lookup',
  },
  [FilterNames.Challenge]: {
    name: FilterNames.Challenge,
    target: 'challengeId',
    operator: '',
    valueType: 'lookup',
  },
  [FilterNames.Email]: {
    name: FilterNames.Email,
    target: 'email',
    operator: '',
    valueType: 'lookup',
  },
  [FilterNames.Campaign]: {
    name: FilterNames.Campaign,
    target: 'campaign',
    operator: '',
    valueType: 'lookup',
  },
  [FilterNames.CustomerTag]: {
    name: FilterNames.CustomerTag,
    target: 'tag',
    operator: '',
    valueType: 'lookup',
  },
  [FilterNames.List]: {
    name: FilterNames.List,
    target: 'list',
    operator: '',
    valueType: 'lookup',
  },
  [FilterNames.Product]: {
    name: FilterNames.Product,
    target: 'product',
    operator: '',
    valueType: 'lookup',
  },
  [FilterNames.Form]: {
    name: FilterNames.Form,
    target: 'form',
    operator: '',
    valueType: 'lookup',
  },
};

export const Operators: Operator[] = [
  { value: '==', label: 'Equals' },
  { value: '!=', label: 'Not Equals' },
  { value: '>', label: 'Greater Than' },
  { value: '>=', label: 'Greater Than or Equals' },
  { value: '<', label: 'Less Than' },
  { value: '<=', label: 'Less Than or Equals' },
];

export const AboveOrBelowOperators: Operator[] = [
  { value: '>', label: 'Greater Than' },
  { value: '>=', label: 'Greater Than or Equals' },
  { value: '<', label: 'Less Than' },
  { value: '<=', label: 'Less Than or Equals' },
];

export const EqualsOnlyOperators: Operator[] = [
  { value: '==', label: 'Equals' },
];

export const YesNoOperators: Operator[] = [
  { value: OperatorLiterals.True, label: 'Yes' },
  { value: OperatorLiterals.False, label: 'No' },
];

// this checks to see whether or not a string has been assigned or not
// in go the undefined value would always be ''
export const ExistanceOfStringOperators: Operator[] = [
  { value: OperatorLiterals.StringDoesNotExist, label: 'Does Not Exist' },
  { value: OperatorLiterals.StringExists, label: 'Exists' },
];

export const TimeScale: { [key: string]: string } = {
  Second: 'Second',
  Minute: 'Minute',
  Hour: 'Hour',
  Day: 'Day',
  Week: 'Week',
};

export enum ValueType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Percent = 'percent',
  StringExistance = 'stringexistance',
  Date = 'date',
}

export const CourseCompletionPercentage = 'course_completion_percentage';
export const CourseViewedPercentage = 'course_viewed_percentage';
export const ChallengeCompletionPercentage = 'challenge_completion_percentage';
export const EmailMailOpened = 'email_mail_opened';
export const EmailMailClicked = 'email_mail_clicked';
export const CampaignMailOpened = 'campaign_mail_opened';
export const CampaignMailClicked = 'campaign_mail_clicked';
export const ProductPurchased = 'purchased_product';
export const TagAdded = 'tag_added';
export const TagRemoved = 'tag_removed';
export const ListAdded = 'list_added';
export const ListRemoved = 'list_removed';
export const Unsubscribed = 'unsubscribed';
export const SubscriptionRenewal = 'subscription_renewal';
export const FormSubmitted = 'form_submitted';
export const FormFieldChecked = 'form_field_checked';
export const SubscriberCreated = 'subscriber_created';
export const MemberCreated = 'member_created';
export const AffiliateCreated = 'affiliate_created';
export const EmailConfirmed = 'email_confirmed';
export const PlatformAssigned = 'platform_assigned';
export const IsTenantTrial = 'is_tenant_trial';
export const WasCustomerTrial = 'was_customer_trial';

export enum EventType {
  CourseSectionCompleted = 'course_section_completed',
  CourseSectionViewed = 'course_section_viewed',
  ChallengeEventAdded = 'challenge_event_added',
  EmailMailOpened = 'email_mail_opened',
  EmailMailClicked = 'email_mail_clicked',
  CampaignMailOpened = 'campaign_mail_opened',
  CampaignMailClicked = 'campaign_mail_clicked',
  EmailConfirmed = 'email_confirmed',
  PlatformAssigned = 'platform_assigned',
  ProductPurchased = 'purchased_product',
  TagAdded = 'tag_added',
  TagRemoved = 'tag_removed',
  ListAdded = 'list_added',
  ListRemoved = 'list_removed',
  Unsubscribed = 'unsubscribed',
  SubscriptionRenewal = 'subscription_renewal',
  FormSubmitted = 'form_submitted',
  SubscriberCreated = 'subscriber_created',
  MemberCreated = 'member_created',
  AffiliateCreated = 'affiliate_created',
}

export const ConditionDefinitions: Record<string, ConditionDefinition> = {
  [FormFieldChecked]: {
    name: 'Form Field Checked',
    group: FilterNames.Form,
    target: FormFieldChecked,
    filterBy: Filters[FilterNames.Form],
    availableOperators: YesNoOperators,
    valueType: 'boolean',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
    dynamicFilterBy: FilterNames.FormCheckBox,
  },
  [EmailConfirmed]: {
    name: 'Email Confirmed',
    group: 'Email',
    target: EmailConfirmed,
    availableOperators: YesNoOperators,
    valueType: 'boolean',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [PlatformAssigned]: {
    name: 'Platform Assigned',
    group: 'Platform',
    target: PlatformAssigned,
    availableOperators: ExistanceOfStringOperators,
    valueType: 'stringexistance',
    platformOnly: true,
    applicableConditionals: [WorkflowStepImplementation.Branch],
  },
  [IsTenantTrial]: {
    name: 'Is Tenant Trial',
    group: 'Platform',
    target: IsTenantTrial,
    availableOperators: YesNoOperators,
    valueType: 'boolean',
    platformOnly: true,
    applicableConditionals: [WorkflowStepImplementation.Branch],
  },
  [WasCustomerTrial]: {
    name: 'Was Customer Trial',
    group: 'Customer',
    target: WasCustomerTrial,
    availableOperators: YesNoOperators,
    valueType: 'boolean',
    platformOnly: true,
    applicableConditionals: [WorkflowStepImplementation.Branch],
  },
  [ProductPurchased]: {
    name: 'Product Purchased',
    group: FilterNames.Product,
    target: ProductPurchased,
    filterBy: Filters[FilterNames.Product],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [TagAdded]: {
    name: 'Tag Added',
    group: FilterNames.CustomerTag,
    target: TagAdded,
    filterBy: Filters[FilterNames.CustomerTag],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [TagRemoved]: {
    name: 'Tag Removed',
    group: FilterNames.CustomerTag,
    target: TagRemoved,
    filterBy: Filters[FilterNames.CustomerTag],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [ListAdded]: {
    name: 'List Added',
    group: FilterNames.List,
    target: ListAdded,
    filterBy: Filters[FilterNames.List],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [ListRemoved]: {
    name: 'List Removed',
    group: FilterNames.List,
    target: ListRemoved,
    filterBy: Filters[FilterNames.List],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  // [Unsubscribed]: {
  //   name: 'Unsubscribed',
  //   group: 'Subscription',
  //   target: Unsubscribed,
  //   availableOperators: EqualsOnlyOperators,
  //   valueType: 'na',
  // },
  // [SubscriptionRenewal]: {
  //   name: 'Subscription Renewal',
  //   group: 'Subscription',
  //   target: SubscriptionRenewal,
  //   availableOperators: EqualsOnlyOperators,
  //   valueType: 'na',
  // },
  // [FormSubmitted]: {
  //   name: 'Form Submitted',
  //   group: FilterNames.Form,
  //   target: FormSubmitted,
  //   filterBy: Filters[FilterNames.Form],
  //   availableOperators: EqualsOnlyOperators,
  //   valueType: 'na',
  //   applicableConditionals: [
  //     WorkflowStepImplementation.Wait,
  //     WorkflowStepImplementation.Branch,
  //   ],
  // },
  [CourseCompletionPercentage]: {
    name: 'Course Completed Percentage',
    group: FilterNames.Course,
    target: CourseCompletionPercentage,
    filterBy: Filters[FilterNames.Course],
    availableOperators: AboveOrBelowOperators,
    valueType: 'percent',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [CourseViewedPercentage]: {
    name: 'Course Viewed Percentage',
    group: FilterNames.Course,
    target: CourseViewedPercentage,
    filterBy: Filters[FilterNames.Course],
    availableOperators: AboveOrBelowOperators,
    valueType: 'percent',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [ChallengeCompletionPercentage]: {
    name: 'Challenge Completed Percentage',
    group: FilterNames.Challenge,
    target: ChallengeCompletionPercentage,
    filterBy: Filters[FilterNames.Challenge],
    availableOperators: AboveOrBelowOperators,
    valueType: 'percent',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [EmailMailOpened]: {
    name: 'Email Opened',
    group: FilterNames.Email,
    target: EmailMailOpened,
    filterBy: Filters[FilterNames.Email],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [EmailMailClicked]: {
    name: 'Email Clicked',
    group: FilterNames.Email,
    target: EmailMailClicked,
    filterBy: Filters[FilterNames.Email],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [CampaignMailOpened]: {
    name: 'Campaign Opened',
    group: FilterNames.Campaign,
    target: CampaignMailOpened,
    filterBy: Filters[FilterNames.Campaign],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
  [CampaignMailClicked]: {
    name: 'Campaign Clicked',
    group: FilterNames.Campaign,
    target: CampaignMailClicked,
    filterBy: Filters[FilterNames.Campaign],
    availableOperators: EqualsOnlyOperators,
    valueType: 'na',
    applicableConditionals: [
      WorkflowStepImplementation.Wait,
      WorkflowStepImplementation.Branch,
    ],
  },
};

export const EventDefinitions: Record<string, EventDefinition> = {
  [EventType.EmailConfirmed]: {
    name: 'Email Confirmed',
    target: EventType.EmailConfirmed,
    entity: EntityType.Customer,
    unfiltered: true,
  },
  [EventType.PlatformAssigned]: {
    name: 'Platform Assigned',
    target: EventType.PlatformAssigned,
    entity: EntityType.Tenant,
    unfiltered: true,
    platformOnly: true,
  },
  [EventType.ProductPurchased]: {
    name: 'Product Purchased',
    target: EventType.ProductPurchased,
    entity: EntityType.Product,
    unfiltered: false,
  },
  [EventType.TagAdded]: {
    name: 'Tag Added',
    target: EventType.TagAdded,
    entity: EntityType.CustomerTag,
    unfiltered: false,
  },
  [EventType.TagRemoved]: {
    name: 'Tag Removed',
    target: EventType.TagRemoved,
    entity: EntityType.CustomerTag,
    unfiltered: false,
  },
  [EventType.ListAdded]: {
    name: 'List Added',
    target: EventType.ListAdded,
    entity: EntityType.CustomerList,
    unfiltered: false,
  },
  [EventType.ListRemoved]: {
    name: 'List Removed',
    target: EventType.ListRemoved,
    entity: EntityType.CustomerList,
    unfiltered: false,
  },
  // [EventType.Unsubscribed]: {
  //   name: 'Unsubscribed',
  //   target: EventType.Unsubscribed,
  //   entity: EntityType.CustomerList,
  //   unfiltered: false,
  // },
  // [EventType.SubscriptionRenewal]: {
  //   name: 'Subscription Renewal',
  //   target: EventType.SubscriptionRenewal,
  //   entity: EntityType.Order,
  //   unfiltered: true,
  // },
  [EventType.FormSubmitted]: {
    name: 'Form Submitted',
    target: EventType.FormSubmitted,
    entity: EntityType.Form,
    unfiltered: false,
  },
  [EventType.SubscriberCreated]: {
    name: 'New Subscriber',
    target: EventType.SubscriberCreated,
    entity: EntityType.Customer,
    unfiltered: true,
  },
  [EventType.MemberCreated]: {
    name: 'New Member',
    target: EventType.MemberCreated,
    entity: EntityType.Customer,
    unfiltered: true,
  },
  // [EventType.AffiliateCreated]: {
  //   name: 'New Affiliate',
  //   target: EventType.AffiliateCreated,
  //   entity: EntityType.Affiliate,
  //   unfiltered: true,
  // },
  [EventType.CourseSectionCompleted]: {
    name: 'Course Section Completed',
    target: EventType.CourseSectionCompleted,
    entity: EntityType.Course,
    unfiltered: false,
  },
  [EventType.CourseSectionViewed]: {
    name: 'Course Section Viewed',
    target: EventType.CourseSectionViewed,
    entity: EntityType.Course,
    unfiltered: false,
  },
  [EventType.ChallengeEventAdded]: {
    name: 'Challenge Activity Added',
    target: EventType.ChallengeEventAdded,
    entity: EntityType.Challenge,
    unfiltered: false,
  },
  [EventType.EmailMailOpened]: {
    name: 'Email Opened',
    target: EventType.EmailMailOpened,
    entity: EntityType.Email,
    unfiltered: false,
  },
  [EventType.EmailMailClicked]: {
    name: 'Email Clicked',
    target: EventType.EmailMailClicked,
    entity: EntityType.Email,
    unfiltered: false,
  },
  [EventType.CampaignMailOpened]: {
    name: 'Campaign Opened',
    target: EventType.CampaignMailOpened,
    entity: EntityType.Campaign,
    unfiltered: false,
  },
  [EventType.CampaignMailClicked]: {
    name: 'Campaign Clicked',
    target: EventType.CampaignMailClicked,
    entity: EntityType.Campaign,
    unfiltered: false,
  },
};

export interface WaitStepData {
  name: string;
  timeOnly: boolean;
  timeoutAmount?: number;
  timeoutScale?: string;
  waitAmount?: number;
  waitScale?: string;
  continueOnTimeout?: boolean;
  criteria: {
    logicalOperator: string;
    conditions: Condition[];
  };
  arguments: string[];
}

export interface SwitchCaseData {
  name: string;
  criteria: {
    logicalOperator: string;
    conditions: Condition[];
  };
  arguments: string[];
  triggers: TriggerData;
}

export interface CampaignRunData {
  title?: string;
  recipients?: number;
  sendAt?: number;
}

export interface EmailStepData {
  name: string;
  emailId: string;
  listIds: string;
  arguments: string[];
}

export interface SlackStepData {
  name: string;
  message: string;
  arguments: string[];
}

export interface TagStepData {
  name: string;
  tagId: string;
  arguments: string[];
}

export interface ListStepData {
  name: string;
  listId: string;
  arguments: string[];
}

export interface ProvisionTenantStepData {
  name: string;
  arguments: string[];
}

export interface CancelTrialWaitStepData {
  name: string;
  arguments: string[];
}

export interface ConvertTrialToPaidStepData {
  name: string;
  arguments: string[];
}

export interface TriggerData {
  events: EventDefinition[];
}

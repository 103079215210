import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CommentProperties, ThreadProperties } from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  TraceService,
  HTTPTraceHeader,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface CommunityAPI {
  requestThreadPublish(
    thread: ThreadProperties,
    text: string
  ): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class CommunityService implements CommunityAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  requestThreadPublish(
    thread: ThreadProperties,
    text: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestThreadPublish);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestThreadPublish
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/thread`,
        { thread, text },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestThreadPublish,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestThreadPublish);
        })
      );
  }

  requestThreadApplaud(
    thread: ThreadProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestThreadApplaud);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestThreadApplaud
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/thread/applaud`,
        { threadId: thread.id, spaceId: thread.data.space },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestThreadApplaud,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestThreadApplaud);
        })
      );
  }

  requestThreadUncordon(
    thread: ThreadProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestThreadUncordon);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestThreadUncordon
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/thread/uncordon`,
        {
          threadId: thread.id,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestThreadUncordon,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestThreadUncordon);
        })
      );
  }

  requestThreadReject(
    thread: ThreadProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestThreadReject);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestThreadReject
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/thread/reject`,
        {
          threadId: thread.id,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestThreadReject,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestThreadReject);
        })
      );
  }

  requestCommentPublish(
    comment: CommentProperties,
    text: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestCommentPublish);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestCommentPublish
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/comment`,
        { comment, text },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestCommentPublish,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestCommentPublish);
        })
      );
  }

  requestCommentApplaud(
    comment: CommentProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestCommentApplaud);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestCommentApplaud
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/comment/applaud`,
        {
          threadId: comment.data.thread,
          spaceId: comment.data.space,
          commentId: comment.id,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestCommentApplaud,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestCommentApplaud);
        })
      );
  }

  requestCommentReport(
    comment: CommentProperties,
    reason: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestCommentReport);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestCommentReport
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/comment/report`,
        {
          threadId: comment.data.thread,
          spaceId: comment.data.space,
          commentId: comment.id,
          reason: reason,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestCommentReport,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestCommentReport);
        })
      );
  }

  requestCommentUncordon(
    comment: CommentProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestCommentUncordon);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestCommentUncordon
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/comment/uncordon`,
        {
          commentId: comment.id,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestCommentUncordon,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestCommentUncordon
            );
        })
      );
  }

  requestCommentReject(
    comment: CommentProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestCommentReject);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestCommentReject
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/comment/reject`,
        {
          commentId: comment.id,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestCommentReject,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestCommentReject);
        })
      );
  }

  requestCommentRemove(
    comment: CommentProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestCommentRemove);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestCommentRemove
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/comment/remove`,
        {
          commentId: comment.id,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestCommentRemove,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestCommentRemove);
        })
      );
  }

  requestCommentIgnore(
    comment: CommentProperties,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.communityRequestCommentIgnore);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.communityRequestCommentIgnore
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/community/comment/ignore`,
        {
          commentId: comment.id,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.communityRequestCommentIgnore,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.communityRequestCommentIgnore);
        })
      );
  }
}

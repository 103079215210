import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface CampaignData {
  title: string;
  lists: string[];
  subject?: string;
  // sendAt: number;
  email: string;
}

export interface CampaignProperties extends StorageProperties {
  data: CampaignData;
  meta: Meta;
  id: string;
}

export class Campaign extends Storage implements CampaignProperties {
  public override data!: CampaignData;

  constructor(options?: CampaignProperties, user?: string) {
    super(options, user, EntityType.Campaign);
    this.update(options?.data);
  }

  public update(data?: CampaignData): void {
    this.data = { ...data } as unknown as CampaignData;
  }
}

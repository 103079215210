import { Pipe, PipeTransform } from '@angular/core';
import { Condition, OperatorLiterals } from '@sidkik/global';

@Pipe({
  name: 'conditionValue',
})
export class ConditionValuePipe implements PipeTransform {
  transform(condition: Condition): string {
    switch (condition.operator) {
      case OperatorLiterals.False:
        return 'No';
      case OperatorLiterals.True:
        return 'Yes';
      case OperatorLiterals.StringDoesNotExist:
        return 'Does not exist';
      case OperatorLiterals.StringExists:
        return 'Exists';
      default:
        return condition.value as string;
    }
  }
}

import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType, Query } from '@sidkik/global';

export interface SegmentData {
  name: string;
  query: Query;
}

export interface SegmentProperties extends StorageProperties {
  data: SegmentData;
  meta: Meta;
  id: string;
}

export class Segment extends Storage implements SegmentProperties {
  public override data!: SegmentData;

  constructor(options?: SegmentProperties, user?: string) {
    super(options, user, EntityType.Segment);
    this.update(options?.data);
  }

  public update(data?: SegmentData): void {
    this.data = { ...data } as unknown as SegmentData;
  }
}

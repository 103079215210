import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface ZoomConfig {
  active: string;
  id: string;
  issue?: string;
}

export interface ZoomConnected {
  connected: boolean;
  issue: string;
}

export interface ZoomAPI {
  getAuthURI(trace?: HTTPTraceHeader): Observable<string>;
  getConnected(trace?: HTTPTraceHeader): Observable<ZoomConnected>;
  getConfig(trace?: HTTPTraceHeader): Observable<ZoomConfig>;
}

@Injectable({
  providedIn: 'root',
})
export class ZoomService implements ZoomAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) { }

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  getAuthURI(trace?: HTTPTraceHeader): Observable<string> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminZoomGetAuthUri);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminZoomGetAuthUri
      );
      internalTrace = true;
    }
    return this.http
      .get<string>(`${this.tenantConfig.api.endpoint}/admin/zoom/auth`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminZoomGetAuthUri, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminZoomGetAuthUri);
        })
      );
  }

  getConnected(trace?: HTTPTraceHeader): Observable<ZoomConnected> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminZoomGetConnected);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminZoomGetConnected
      );
      internalTrace = true;
    }
    return this.http
      .get<ZoomConnected>(
        `${this.tenantConfig.api.endpoint}/admin/zoom/connected`,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminZoomGetConnected, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminZoomGetConnected);
        })
      );
  }

  getConfig(trace?: HTTPTraceHeader): Observable<ZoomConfig> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminZoomGetConfig);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminZoomGetConfig
      );
      internalTrace = true;
    }
    return this.http
      .get<ZoomConfig>(`${this.tenantConfig.api.endpoint}/admin/zoom/config`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminZoomGetConfig, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminZoomGetConfig);
        })
      );
  }
}

import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@sidkik/global';
import { AppUpdateService } from './services/app-update.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AuthzFacade } from '@sidkik/authz';
import { filter, take } from 'rxjs';

@Component({
  selector: 'sidkik-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'tenant';
  clarityId;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject('APP_VERSION') public version: string,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private appUpdateService: AppUpdateService,
    private authzFacade: AuthzFacade
  ) {
    this.clarityId = this.appConfig?.addon?.clarityId;
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if ((window as any)['clarity']) {
          logger.info('app:clarity', 'setting version', version);
          (window as any)['clarity']('set', 'version', version);
        }
      }, 30000);
    }
  }

  ngOnInit() {
    this.appUpdateService.init(this.appConfig);
    this.addTawkTo();
  }

  /**
   * add tawk.to integration if enabled in config
   */
  addTawkTo() {
    this.authzFacade.me$
      .pipe(
        filter((me) => me !== null && me !== undefined),
        take(1)
      )
      .subscribe(async (me) => {});

    this.authzFacade.user$
      .pipe(
        filter((user) => user !== null && user !== undefined),
        take(1)
      )
      .subscribe(async (user) => {
        if ((window as any)['clarity']) {
          logger.info('app:clarity', 'setting customer', user?.email);
          (window as any)['clarity']('identify', user?.email);
          (window as any)['clarity'](
            'set',
            'tenant',
            this.appConfig.firebase.tenantId
          );
        }
        if (this.appConfig?.addon?.tawkTo?.chatLink) {
          logger.trace(
            'app:tenant:app.component:addTawkTo',
            'adding tawkto',
            this.appConfig?.firebase.projectId,
            user?.email
          );
          const s = this.doc.createElement('script');
          s.type = 'text/javascript';
          s.innerHTML = `var Tawk_API=Tawk_API||{};
          Tawk_LoadStart=new Date();
          (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='${this.appConfig.addon.tawkTo.chatLink}';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
          })();
          Tawk_API.onLoad = function(){
            const attrs = {
              'tenant' : '${this.appConfig?.firebase.tenantId}',
              'email': '${user?.email}',
              'name': '${this.appConfig?.firebase.tenantId} | ${user?.email}',
            }
            Tawk_API.setAttributes(attrs, (error) => {
              if (error) {
                // console.log('tawkto set attributes error', error);
                return;
              }
              // console.log('tawkto set attributes', attrs);
            });
          };
          `;
          const head = this.doc.getElementsByTagName('head')[0];
          head.appendChild(s);
        }
      });
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authzStateKey, globalStateKey } from '@sidkik/global';
import { LAYOUT_FEATURE_KEY, LayoutState } from './layout.reducer';

// Lookup the 'Layout' feature state managed by NgRx
export const getLayoutState =
  createFeatureSelector<LayoutState>(LAYOUT_FEATURE_KEY);

export const getGlobalState = createFeatureSelector<any>(globalStateKey);
export const getAuthzState = createFeatureSelector<any>(authzStateKey);

export const getGlobalBreadcrumbTrail = createSelector(
  getGlobalState,
  (state: any) => state.breadcrumbTrail
);

export const getAuthzUser = createSelector(
  getAuthzState,
  (state: any) => state.user
);

export const getStripeAccount = createSelector(
  getLayoutState,
  (state: LayoutState) => state.stripe
);

export const getStripeAccountError = createSelector(
  getLayoutState,
  (state: LayoutState) => state.error
);

export const getZoomConnected = createSelector(
  getLayoutState,
  (state: LayoutState) => state.zoom?.connected ?? false
);

export const getZoomConnectedError = createSelector(
  getLayoutState,
  (state: LayoutState) => state.error
);

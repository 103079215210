export const setQuickbooksNotConnectedType =
  '[integrations/quickbooks] set not connected';
export const connectionForQuickbooksEstablished =
  '[integrations/quickbooks] connection established';

export const loadQuickbooksAccountsType =
  '[integrations/quickbooks/account] load accounts';
export const loadQuickbooksAccountsSuccessType =
  '[integrations/quickbooks/account] load accounts success';
export const loadQuickbooksAccountsFailureType =
  '[integrations/quickbooks/account] load accounts failure';

export const loadQuickbooksVendorsType =
  '[integrations/quickbooks/vendors] load vendors';
export const loadQuickbooksVendorsSuccessType =
  '[integrations/quickbooks/vendors] load vendors success';
export const loadQuickbooksVendorsFailureType =
  '[integrations/quickbooks/vendors] load vendors failure';

export const loadQuickbooksConfigType =
  '[integrations/quickbooks/config] load config';
export const loadQuickbooksConfigSuccessType =
  '[integrations/quickbooks/config] load config success';
export const loadQuickbooksConfigFailureType =
  '[integrations/quickbooks/config] load config failure';

export const updateQuickbooksConfigType =
  '[integrations/quickbooks/config] update config';
export const updateQuickbooksConfigSuccessType =
  '[integrations/quickbooks/config] update config success';
export const updateQuickbooksConfigFailureType =
  '[integrations/quickbooks/config] update config failure';

export const loadStripeConfigType = '[integrations/stripe/config] load config';
export const loadStripeConfigSuccessType =
  '[integrations/stripe/config] load config success';
export const loadStripeConfigFailureType =
  '[integrations/stripe/config] load config failure';

export const setStripeNotConnectedType =
  '[integrations/stripe] set not connected';
export const connectionForStripeEstablished =
  '[integrations/stripe] connection established';

export const loadActiveCampaignConfigType =
  '[integrations/active-campaign/config] load config';
export const loadActiveCampaignConfigSuccessType =
  '[integrations/active-campaign/config] load config success';
export const loadActiveCampaignConfigFailureType =
  '[integrations/active-campaign/config] load config failure';

export const updateActiveCampaignConfigType =
  '[integrations/active-campaign/config] update config';
export const updateActiveCampaignConfigSuccessType =
  '[integrations/active-campaign/config] update config success';
export const updateActiveCampaignConfigFailureType =
  '[integrations/active-campaign/config] update config failure';

export const setActiveCampaignNotConnectedType =
  '[integrations/active-campaign] set not connected';
export const connectionForActiveCampaignEstablished =
  '[integrations/active-campaign] connection established';

export const loadVimeoConfigType = '[integrations/vimeo/config] load config';
export const loadVimeoConfigSuccessType =
  '[integrations/vimeo/config] load config success';
export const loadVimeoConfigFailureType =
  '[integrations/vimeo/config] load config failure';

export const updateVimeoConfigType =
  '[integrations/vimeo/config] update config';
export const updateVimeoConfigSuccessType =
  '[integrations/vimeo/config] update config success';
export const updateVimeoConfigFailureType =
  '[integrations/vimeo/config] update config failure';

export const setVimeoNotConnectedType =
  '[integrations/vimeo] set not connected';
export const setVimeoConnectedType = '[integrations/vimeo] set connected';
export const connectionForVimeoEstablished =
  '[integrations/vimeo] connection established';

export const loadMailerConfigType = '[integrations/mailer/config] load config';
export const loadMailerConfigSuccessType =
  '[integrations/mailer/config] load config success';
export const loadMailerConfigFailureType =
  '[integrations/mailer/config] load config failure';

export const updateMailerConfigType =
  '[integrations/mailer/config] update config';
export const updateMailerConfigSuccessType =
  '[integrations/mailer/config] update config success';
export const updateMailerConfigFailureType =
  '[integrations/mailer/config] update config failure';

export const loadSlackConfigType = '[integrations/slack/config] load config';
export const loadSlackConfigSuccessType =
  '[integrations/slack/config] load config success';
export const loadSlackConfigFailureType =
  '[integrations/slack/config] load config failure';

export const updateSlackConfigType =
  '[integrations/slack/config] update config';
export const updateSlackConfigSuccessType =
  '[integrations/slack/config] update config success';
export const updateSlackConfigFailureType =
  '[integrations/slack/config] update config failure';

export const setSlackNotConnectedType =
  '[integrations/slack] set not connected';
export const setSlackConnectedType = '[integrations/slack] set connected';
export const connectionForSlackEstablished =
  '[integrations/slack] connection established';

export const loadZoomConfigType = '[integrations/zoom/config] load config';
export const loadZoomConfigSuccessType =
  '[integrations/zoom/config] load config success';
export const loadZoomConfigFailureType =
  '[integrations/zoom/config] load config failure';

export const updateZoomConfigType = '[integrations/zoom/config] update config';
export const updateZoomConfigSuccessType =
  '[integrations/zoom/config] update config success';
export const updateZoomConfigFailureType =
  '[integrations/zoom/config] update config failure';

export const setZoomNotConnectedType = '[integrations/zoom] set not connected';
export const setZoomConnectedType = '[integrations/zoom] set connected';
export const connectionForZoomEstablished =
  '[integrations/zoom] connection established';

import { Pipe, PipeTransform } from '@angular/core';
import { EventDefinitions } from '@sidkik/global';

@Pipe({
  name: 'eventDefinition',
})
export class EventDefinitionPipe implements PipeTransform {
  transform(target: string): string {
    const definition = EventDefinitions[target];
    return definition ? `${definition.name}` : '';
  }
}

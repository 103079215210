import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { Integrations } from '../integrations';
import { EntityType } from '@sidkik/global';

export enum RefundRequestStatus {
  Requested = 'requested',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Errored = 'errored',
}

export interface RefundRequestData {
  amount: number;
  chargeId: string;
  customerId: string;
  reason: string;
  status: RefundRequestStatus;
  orderId: string;
  subscriptionId: string;
  issue?: string;
  reasonCode?: string;
}

export interface RefundRequestProperties extends StorageProperties {
  data: RefundRequestData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

export class RefundRequest extends Storage implements RefundRequestProperties {
  public override data!: RefundRequestData;

  constructor(options?: RefundRequestProperties, user?: string) {
    super(options, user, EntityType.RefundRequest);
    this.update(options?.data);
  }

  public update(data?: RefundRequestData): void {
    this.data = { ...data } as unknown as RefundRequestData;
  }
}

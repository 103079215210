import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';
import { AssetProperties } from '../library';

export interface Palette {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export interface ThemeConfigData {
  palette: {
    primary: Palette;
    secondary: Palette;
    error?: Palette;
    warn?: Palette;
    info?: Palette;
  };
  logo: AssetProperties;
  smallLogo: AssetProperties;
  favicon: AssetProperties;
  siteName: string;
  replyToAddress: string;
  replyToName: string;
  fontCustom1: string;
  fontCustom2: string;
  fontMenu: string;
  fontHeadings: string;
  fontFooter: string;
  fontCopy: string;
}

export interface ThemeConfigProperties extends StorageProperties {
  data: ThemeConfigData;
  meta: Meta;
  id: string;
}

export class ThemeConfig extends Storage implements ThemeConfigProperties {
  public override data!: ThemeConfigData;

  constructor(options?: ThemeConfigProperties, user?: string) {
    super(options, user, EntityType.ThemeConfiguration);
    this.id = 'theme';
    if (options?.data) this.update(options?.data);
  }

  public update(data: ThemeConfigData): void {
    this.data = { ...this.data, ...data };
  }
}

export const environment = {
  production: true,
  emulator: false,
  secondaryProject: {
    apiKey: 'AIzaSyCiaPZrnQn-j4eqqeEUvW6tNt5zixK5LdI',
    projectId: 'ep-core-prod',
    appId: '1:521777939243:web:784198a6e1b835e2273b6e',
    measurementId: 'G-06173ZCQ29',
  },
  mock: false,
};

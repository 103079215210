import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface CustomerListData {
  name: string;
  description: string;
  customerCount?: number;
}

export interface CustomerListProperties extends StorageProperties {
  data: CustomerListData;
  meta: Meta;
  id: string;
}

export class CustomerList extends Storage implements CustomerListProperties {
  public override data!: CustomerListData;

  constructor(options?: CustomerListProperties, user?: string) {
    super(options, user, EntityType.CustomerList);
    this.update(options?.data);
  }

  public update(data?: CustomerListData): void {
    this.data = { ...data } as unknown as CustomerListData;
  }

  public incrementCustomerCount(incr: number): void {
    this.data.customerCount = (this.data.customerCount || 0) + incr;
  }
}

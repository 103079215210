import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface AccountingConfigData {
  paymentCaptureWindow?: number;
  transactionFeePercent?: number;
  transactionFeeStatic?: number;
  revRecMonthlyWaitDays?: number;
  revRecMonthlyRecognitions?: number;
  revRecYearlyWaitDays?: number;
  revRecYearlyRecognitions?: number;
  affiliatePayoutWaitPeriodDays?: number;
}

export interface AccountingConfigProperties extends StorageProperties {
  data: AccountingConfigData;
  meta: Meta;
  id: string;
}

export class AccountingConfig
  extends Storage
  implements AccountingConfigProperties
{
  public override data!: AccountingConfigData;

  constructor(options?: AccountingConfigProperties, user?: string) {
    super(options, user, EntityType.AccountingConfiguration);
    this.id = 'accounting';
    if (options?.data) this.update(options?.data);
  }

  public update(data: AccountingConfigData): void {
    this.data = { ...this.data, ...data };
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  SeriesChart,
  Series,
  SeriesData,
  BarChart,
  SpanTypes,
  TraceService,
} from '@sidkik/global';
import { DateTime } from 'luxon';
import { Observable, catchError, map, tap, throwError } from 'rxjs';

export interface AnalyticsAPI {
  getBasicWeb(trace?: HTTPTraceHeader): Observable<any[]>;
}

export interface ReportData {
  name: string;
  report: Report;
  property: string;
}

export interface Report {
  kind: string;
  metadata: Metadata;
  metricHeaders: MetricHeader[];
  rowCount: number;
  rows: Row[];
  dimensionHeaders?: DimensionHeader[];
}

export interface DimensionHeader {
  name: string;
}

export interface Metadata {
  currencyCode: string;
  timeZone: string;
}

export interface MetricHeader {
  name: string;
  type: string;
}

export interface Row {
  metricValues: Value[];
  dimensionValues?: Value[];
}

export interface Value {
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements AnalyticsAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  getBasicWeb(trace?: HTTPTraceHeader): Observable<any[]> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminAnalyticsGetBasicWeb);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminAnalyticsGetBasicWeb
      );
      internalTrace = true;
    }
    return this.http
      .get<any[]>(
        `${this.tenantConfig.api.endpoint}/admin/analytics/web/basic`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAnalyticsGetBasicWeb, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAnalyticsGetBasicWeb);
        }),
        map((data) => this.convertToChartableData(data))
      );
  }

  convertToChartableData(data: ReportData[]): any {
    let chartsData: any = {};

    // loop through reports
    data.forEach((r) => {
      if (r.report && r.report.dimensionHeaders) {
        switch (r.report.dimensionHeaders[0].name) {
          case 'date':
            const sd = this.convertToSeriesChart(r);
            chartsData[r.name] = sd;
            break;
          case 'pageTitle':
            const bc = this.convertToBarChart(r);
            chartsData[r.name] = bc;
            break;
          default:
            break;
        }
      } else {
        chartsData = { ...chartsData, ...this.convertToMetricsData(r) };
      }
    });
    return chartsData;
  }

  convertToMetricsData(data: ReportData): any[] {
    const metricsData: any = {};
    data.report?.rows?.forEach((r) => {
      r.metricValues.forEach((m, i) => {
        const metricData: any = {};
        metricData.name = data.report.metricHeaders[i].name;
        metricData.value = m.value;
        metricsData[metricData.name] = metricData;
      });
    });
    return metricsData;
  }

  convertToBarChart(data: ReportData): BarChart {
    const barChart: BarChart = {
      name: data.name,
      property: data.property,
      data: [],
    };

    data.report?.rows?.forEach((r) => {
      const row: Partial<SeriesData> = {};
      if (r.dimensionValues && r.dimensionValues.length > 0) {
        row.name = r.dimensionValues[0].value ?? '';
      }
      row.value = r.metricValues[0].value;
      barChart.data.push(row as SeriesData);
    });

    return barChart;
  }

  convertToSeriesChart(data: ReportData): SeriesChart {
    const seriesChart: SeriesChart = {
      name: data.name,
      property: data.property,
      series: [],
    };

    const series: Series[] = [];
    data.report?.metricHeaders?.forEach((m) => {
      // convert name from camelCase to Title Case
      let name = m.name.replace(/([A-Z])/g, ' $1').trim();
      name = name.charAt(0).toUpperCase() + name.slice(1);
      series.push({
        name,
        series: [],
      });
    });

    let isDateDimension = false;
    if (
      data.report.dimensionHeaders &&
      data.report.dimensionHeaders.length > 0
    ) {
      switch (data.report.dimensionHeaders[0].name) {
        case 'date':
          isDateDimension = true;
          break;
        default:
          isDateDimension = false;
          break;
      }
    }

    // loop through rows
    data.report?.rows?.forEach((r) => {
      // loop through metric values
      r.metricValues.forEach((m, i) => {
        const row: Partial<SeriesData> = {};
        if (r.dimensionValues && r.dimensionValues.length > 0) {
          if (isDateDimension) {
            // parse date from YYYYMMDD
            row.name = DateTime.fromFormat(
              r.dimensionValues[0].value,
              'yyyyMMdd'
            ).toJSDate();
          } else {
            row.name = r.dimensionValues[0]?.value ?? '';
          }
        }
        row.value = m.value;
        series[i].series.push(row as SeriesData);
      });
    });

    if (isDateDimension) {
      // sort by date ascending
      series?.forEach((s) => {
        s.series.sort((a, b) => {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });
      });
    }

    seriesChart.series = series;

    return seriesChart;
  }
}

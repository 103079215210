import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface VimeoConfig {
  active: string;
  id: string;
  domains: string[];
  accountType: string;
}

export interface VimeoAPI {
  getAuthURI(trace?: HTTPTraceHeader): Observable<string>;
  getConnected(trace?: HTTPTraceHeader): Observable<null>;
  getConfig(trace?: HTTPTraceHeader): Observable<VimeoConfig>;
}

@Injectable({
  providedIn: 'root',
})
export class VimeoService implements VimeoAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  getAuthURI(trace?: HTTPTraceHeader): Observable<string> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminVimeoGetAuthUri);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminVimeoGetAuthUri
      );
      internalTrace = true;
    }
    return this.http
      .get<string>(`${this.tenantConfig.api.endpoint}/admin/vimeo/auth`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminVimeoGetAuthUri, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminVimeoGetAuthUri);
        })
      );
  }

  getConnected(trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminVimeoGetConnected);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminVimeoGetConnected
      );
      internalTrace = true;
    }
    return this.http
      .get<null>(`${this.tenantConfig.api.endpoint}/admin/vimeo/connected`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminVimeoGetConnected, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminVimeoGetConnected);
        })
      );
  }

  getConfig(trace?: HTTPTraceHeader): Observable<VimeoConfig> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminVimeoGetConfig);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminVimeoGetConfig
      );
      internalTrace = true;
    }
    return this.http
      .get<VimeoConfig>(
        `${this.tenantConfig.api.endpoint}/admin/vimeo/config`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminVimeoGetConfig, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminVimeoGetConfig);
        })
      );
  }
}

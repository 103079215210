import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface PromoCodeData {
  code: string;
  codeLowercase: string;
  firstTimeOrderOnly: boolean;
  restrictToCustomer?: string;
  maxRedemptions?: number;
  redemptions?: number;
  validUntil?: number;
  minOrderAmount?: number;
  coupon: string;
  active: boolean;
}

export interface PromoCodeProperties extends StorageProperties {
  data: PromoCodeData;
  meta: Meta;
  id: string;
}

export class PromoCode extends Storage implements PromoCodeProperties {
  public override data!: PromoCodeData;

  constructor(options?: PromoCodeProperties, user?: string) {
    super(options, user, EntityType.PromoCode);
    this.update(options?.data);
  }

  public update(data?: PromoCodeData, trackChanges = false): void {
    if (trackChanges) {
      super.checkIfSync(data);
    }
    this.data = { ...data } as unknown as PromoCodeData;
  }
}

import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export enum MailerType {
  None = '',
  Mailchimp = 'Mailchimp',
  SendGrid = 'SendGrid',
}

export interface MailchimpConfig {
  transactionalApiKey: string;
}

export interface SendGridConfig {
  apiKey: string;
  webhookPublicKey?: string;
  issue?: string;
}

export interface MailerConfigData {
  type: MailerType;
  mailchimp?: MailchimpConfig;
  sendgrid?: SendGridConfig;
  active: boolean;
  fromName: string;
  fromEmail: string;
  replyToName: string;
  replyToEmail: string;
  canSpamAddress: string;
}

export interface MailerConfigProperties extends StorageProperties {
  data: MailerConfigData;
  meta: Meta;
  id: string;
}

export class MailerConfig extends Storage implements MailerConfigProperties {
  public override data!: MailerConfigData;

  constructor(options?: MailerConfigProperties, user?: string) {
    super(options, user, EntityType.MailerConfiguration);
    this.update(options?.data);
  }

  public update(data?: MailerConfigData): void {
    this.data = { ...data } as unknown as MailerConfigData;
  }
}

import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface CouponData {
  name: string;
  valid: boolean;
  appliesTo?: string[];
  percentOff?: number;
  amountOff?: number;
  validUntil?: number;
  maxRedemptions?: number;
  redemptions?: number;
  duration: string;
  durationTimes?: number;
  description?: string;
}

export interface CouponProperties extends StorageProperties {
  data: CouponData;
  meta: Meta;
  id: string;
}

export class Coupon extends Storage implements CouponProperties {
  public override data!: CouponData;

  constructor(options?: CouponProperties, user?: string) {
    super(options, user, EntityType.Coupon);
    this.update(options?.data);
  }

  public update(data?: CouponData, trackChanges = false): void {
    if (trackChanges) {
      super.checkIfSync(data);
    }
    this.data = { ...data } as unknown as CouponData;
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AppConfig,
  APP_CONFIG,
  StripeAccount,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
  StripeBalance,
} from '@sidkik/global';
import {
  Observable,
  catchError,
  shareReplay,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import { retryWithBackoff } from '../operators/retry-with-backoff.operator';

export interface StripeAPI {
  getAccount(trace?: HTTPTraceHeader): Observable<StripeAccount>;
  getBalance(trace?: HTTPTraceHeader): Observable<StripeBalance>;
  fundAccount(amount: number, trace?: HTTPTraceHeader): Observable<any>;
  getConnectOAuthLink(trace?: HTTPTraceHeader): Observable<{ uri: string }>;
}

@Injectable({
  providedIn: 'root',
})
export class StripeService implements StripeAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  fundAccount(
    amount: number,
    trace?: HTTPTraceHeader | undefined
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminStripeFundAccount);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminStripeFundAccount
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/stripe/fund`,
        { amount },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeFundAccount, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeFundAccount);
        })
      );
  }

  getBalance(trace?: HTTPTraceHeader): Observable<StripeBalance> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminStripeGetBalance);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminStripeGetBalance
      );
      internalTrace = true;
    }
    return this.http
      .get<StripeBalance>(
        `${this.tenantConfig.api.endpoint}/admin/stripe/balance`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeGetBalance, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeGetBalance);
        })
      );
  }

  getAccount(trace?: HTTPTraceHeader): Observable<StripeAccount> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminStripeGetAccount);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminStripeGetAccount
      );
      internalTrace = true;
    }
    return this.http
      .get<StripeAccount>(
        `${this.tenantConfig.api.endpoint}/admin/stripe/account`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeGetAccount, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeGetAccount);
        })
      );
  }

  getConnectOAuthLink(trace?: HTTPTraceHeader): Observable<{ uri: string }> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminStripeGetOAuthLink);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminStripeGetOAuthLink
      );
      internalTrace = true;
    }
    return this.http
      .get<{ uri: string }>(
        `${this.tenantConfig.api.endpoint}/admin/stripe/connect/link/oauth`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeGetOAuthLink, err);
          return throwError(() => err);
        }),
        tap(
          () =>
            internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeGetOAuthLink)
        )
      );
  }

  upsertConnectKey(key: string, trace?: HTTPTraceHeader): Observable<any> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminStripeUpsertConnectKey);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminStripeUpsertConnectKey
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/admin/stripe/connect/key`,
        { key },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        timeout(this.timeoutMs),
        retryWithBackoff(
          this.retryConfig.retryDelayMs,
          this.retryConfig.retries,
          this.retryConfig.backoffMs
        ),
        catchError((err) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.adminStripeUpsertConnectKey,
              err
            );
          err.details = { function: 'upsertConnectKey' };
          return throwError(() => err);
        }),
        shareReplay(1),
        tap(
          () =>
            internalTrace &&
            this.traceService.endSpan(SpanTypes.adminStripeUpsertConnectKey)
        )
      );
  }
}

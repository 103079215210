import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface SlackConfigData {
  id: string;
  active: boolean;
  defaultChannel?: string;
}

export interface SlackConfigProperties extends StorageProperties {
  data: SlackConfigData;
  meta: Meta;
  id: string;
}

export class SlackConfig extends Storage implements SlackConfigProperties {
  public override data!: SlackConfigData;

  constructor(options?: SlackConfigProperties, user?: string) {
    super(options, user, EntityType.SlackConfiguration);
    this.update(options?.data);
  }

  public update(data?: SlackConfigData): void {
    this.data = { ...data } as unknown as SlackConfigData;
  }
}

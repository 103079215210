import { Integrations } from '../integrations';
import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export enum AssetStatus {
  new = 'preparing',
  readyToUpload = 'readyToUpload',
  retryUpload = 'retryUpload',
  uploading = 'uploading',
  uploaded = 'uploaded',
  done = 'done',
  processing = 'processing',
  ready = '',
  error = 'error',
}

export interface Thumbnail {
  thumbnailSize: string;
  bucket: string;
  key: string;
  size: number;
  width: number;
  height: number;
  type: string;
  token?: string;
  link: string;
}

export interface VideoAttributes {
  duration: number;
  height: number;
  width: number;
}

export interface ImageAttributes {
  height: number;
  width: number;
}

export interface Stream {
  success: boolean;
  errors?: any[];
  messages?: any[];
  externalId: string;
  picture?: {
    sizes?: {
      link?: string;
    }[];
  };
  vimeo?: boolean;
  id?: string;
}

export interface AssetData {
  name?: string;
  bucket?: string;
  token?: string;
  key?: string;
  linkedCourses?: string[];
  thumbnails?: Thumbnail[];
  status?: AssetStatus;
  processingPercentage?: number;
  uploadedBytes: number;
  size: number;
  totalSize?: number;
  type: string;
  url: string;
  attr?: VideoAttributes | ImageAttributes;
  stream?: Stream;
}

export interface AssetProperties extends StorageProperties {
  data: AssetData;
  meta: Meta;
  integrations: Integrations;
  id: string;
}

export class Asset extends Storage implements AssetProperties {
  public override data!: AssetData;
  constructor(options?: AssetProperties, user?: string) {
    super(options, user, EntityType.Asset);
    this.update(options?.data);
  }

  public update(data?: AssetData): Asset {
    this.data = { ...this.data, ...data };
    return this;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateAuthenticated, canMatchAuthenticated, AuthzRoutes } from '@sidkik/authz';
import { ContainerComponent, HelpComponent } from '@sidkik/tenant-layout';

const routes: Routes = [
  {
    path: 'auth',
    children: AuthzRoutes,
    // loadChildren: () => import('@sidkik/authz').then((m) => m.AuthzModule),
  },
  {
    path: '',
    redirectTo: '/analytics/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ContainerComponent,
    canMatch: [canMatchAuthenticated],
    canActivate: [canActivateAuthenticated],
    children: [
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'help',
        component: HelpComponent,
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'analytics',
        loadChildren: () =>
          import('@sidkik/analytics').then((m) => m.AnalyticsModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'library',
        loadChildren: () =>
          import('@sidkik/library').then((m) => m.LibraryModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'community',
        loadChildren: () =>
          import('@sidkik/community').then((m) => m.CommunityModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'people',
        loadChildren: () =>
          import('@sidkik/people').then((m) => m.PeopleModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'catalog',
        loadChildren: () =>
          import('@sidkik/catalog').then((m) => m.CatalogModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'integrations',
        loadChildren: () =>
          import('@sidkik/integrations').then((m) => m.IntegrationsModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'config',
        loadChildren: () =>
          import('@sidkik/tenant-settings').then((m) => m.TenantSettingsModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'people',
        loadChildren: () =>
          import('@sidkik/people').then((m) => m.PeopleModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'scheduling',
        loadChildren: () =>
          import('@sidkik/scheduling').then((m) => m.SchedulingModule),
      },
      {
        canMatch: [canMatchAuthenticated],
        canActivate: [canActivateAuthenticated],
        path: 'profile',
        loadChildren: () =>
          import('@sidkik/tenant-user-profile').then(
            (m) => m.TenantUserProfileModule
          ),
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RoutingModule { }

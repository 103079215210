import { StorageProperties, Storage } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';
import {
  BookingPaymentStatus,
  BookingPhysicalLocation,
  BookingStatus,
  BookingVirtualLocation,
} from './common';

export interface BookingData {
  customerId: string;
  productId: string;
  start: number;
  end: number;
  order_id: string;
  status: BookingStatus;
  bookedWith?: string;
  bookedWithId?: string;
  bookedWithAvatar?: string;
  virtualLocation?: BookingVirtualLocation;
  physicalLocation?: BookingPhysicalLocation;
  totalBookingsInProduct?: number;
  bulkBookingId?: string;
  bookingRelativeOrder?: number;
  isPrivate?: boolean;
  paymentStatus?: BookingPaymentStatus;
  paymentTime?: number;
  duration?: number;
  shortBookingName?: string;
  paymentIssue?: string;
  customerName?: string;
  customerAvatar?: string;
  userTimeZone?: string;
}

export interface BookingProperties extends StorageProperties {
  data: BookingData;
  meta: Meta;
  id: string;
  tenant?: string;
}

export class Booking extends Storage implements BookingProperties {
  public override data!: BookingData;

  constructor(options?: BookingProperties, user?: string) {
    super(options, user, EntityType.Booking);
    this.update(options?.data);
  }

  public update(data?: BookingData): void {
    this.data = {
      ...(this.data ?? {}),
      ...data,
    } as unknown as BookingData;
  }
}

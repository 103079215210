import { ComboBoxOption } from "../../models";

export enum FontNames {
  Inter = 'Inter Variable',
  Lexend = 'Lexend Variable',
  BodoniModa = 'Bodoni Moda Variable',
  Poppins = 'Poppins',
  Oswald = 'Oswald Variable',
  Lato = 'Lato',
  Roboto = 'Roboto',
  Montserrat = 'Montserrat Variable',
  OpenSans = 'Open Sans Variable',
}

export const FontNamesComboOptions: ComboBoxOption[] = [
  { value: FontNames.BodoniModa, label: 'Bodoni Moda' },
  { value: FontNames.Inter, label: 'Inter' },
  { value: FontNames.Lato, label: 'Lato' },
  { value: FontNames.Lexend, label: 'Lexend' },
  { value: FontNames.Montserrat, label: 'Montserrat' },
  { value: FontNames.OpenSans, label: 'Open Sans' },
  { value: FontNames.Oswald, label: 'Oswald' },
  { value: FontNames.Poppins, label: 'Poppins' },
  { value: FontNames.Roboto, label: 'Roboto' },
];
import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface SchedulingConfigData {
  autoMarkCompletedHours?: number;
  minAllowableBookingHours?: number;
  bookingReminderHoursBefore?: number;
  bookingStartingMinutesBefore?: number;
  maxAvailabilityDays?: number;
  timeBetweenSessionsMinutes?: number;
}

export interface SchedulingConfigProperties extends StorageProperties {
  data: SchedulingConfigData;
  meta: Meta;
  id: string;
}

export class SchedulingConfig
  extends Storage
  implements SchedulingConfigProperties
{
  public override data!: SchedulingConfigData;

  constructor(options?: SchedulingConfigProperties, user?: string) {
    super(options, user, EntityType.SchedulingConfiguration);
    this.id = 'scheduling';
    if (options?.data) this.update(options?.data);
  }

  public update(data: SchedulingConfigData): void {
    this.data = { ...this.data, ...data };
  }
}

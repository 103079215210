import { Pipe, PipeTransform } from '@angular/core';
import { ConditionDefinitions } from '@sidkik/global';

@Pipe({
  name: 'conditionDefinition',
})
export class ConditionDefinitionPipe implements PipeTransform {
  transform(target: string): string {
    const definition = ConditionDefinitions[target];
    return definition ? `${definition.name}` : '';
  }
}

import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface CommunityModeration {
  toxic: number;
  insult: number;
  profanity: number;
  derogatory: number;
  sexual: number;
  deathHarmAndTragedy: number;
  violent: number;
  firearmsAndWeapons: number;
  publicSafety: number;
  health: number;
  religionAndBelief: number;
  illicitDrugs: number;
  warAndConflict: number;
  politics: number;
  finance: number;
  legal: number;
}

export interface CommunityConfigData {
  moderation: CommunityModeration;
}

export interface CommunityConfigProperties extends StorageProperties {
  data: CommunityConfigData;
  meta: Meta;
  id: string;
}

export class CommunityConfig
  extends Storage
  implements CommunityConfigProperties
{
  public override data!: CommunityConfigData;

  constructor(options?: CommunityConfigProperties, user?: string) {
    super(options, user, EntityType.CommunityConfiguration);
    this.update(options?.data);
  }

  public update(data?: CommunityConfigData): void {
    this.data = { ...data } as unknown as CommunityConfigData;
  }
}

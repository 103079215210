import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { authzStateKey } from '@sidkik/global';
@Injectable({
  providedIn: 'root',
})
export class AuthzService {
  store = inject(Store);

  authz$ = this.store.select(authzStateKey);
}

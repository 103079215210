import { createReducer, on, Action } from '@ngrx/store';

import * as LayoutActions from './layout.actions';
import { StripeAccount, tenantLayoutStateKey } from '@sidkik/global';
import { ZoomConnected } from '@sidkik/sidkik-api';

export const LAYOUT_FEATURE_KEY = tenantLayoutStateKey;

export interface LayoutState {
  stripe?: StripeAccount | undefined; // which Layout record has been selected
  zoom?: ZoomConnected | null; // which Zoom record has been selected
  error?: any | null; // last none error (if any)
}

export interface LayoutPartialState {
  readonly [LAYOUT_FEATURE_KEY]: LayoutState;
}

export const initialLayoutState: LayoutState = {
  // set initial required properties
  stripe: undefined,
  zoom: undefined,
  error: null,
};

const reducer = createReducer(
  initialLayoutState,
  on(LayoutActions.loadStripeAccountSuccess, (state, { stripe }) => ({
    ...state,
    stripe,
  })),
  on(LayoutActions.loadStripeAccountFailure, (state, { error }) => ({
    ...state,
    stripe: undefined,
    error,
  })),
  on(LayoutActions.loadZoomConnectionSuccess, (state, { zoom }) => ({
    ...state,
    zoom,
  })),
  on(LayoutActions.loadZoomConnectionFailure, (state, { error }) => ({
    ...state,
    zoom: undefined,
    error,
  }))
);

export function layoutReducer(state: LayoutState | undefined, action: Action) {
  return reducer(state, action);
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {
  AppConfig,
  APP_CONFIG,
  AuthzConfig,
  AUTHZ_CONFIG,
  BrandingConfig,
  BRANDING_CONFIG,
  ANALYTICS_IS_SUPPORTED,
  IS_PLATFORM_ENABLED,
  createLogWriter,
  createLogMethods,
  Logger,
  LOGGER_ENABLED,
  LOGGER_FILTER,
  APP_CHECK_DEBUG_TOKEN,
  STRIPE_ENABLED,
  MOCK_ENABLED,
  SIDKIK_PROJECT,
  SIDKIK_TENANT,
  SIDKIK_AUTH_TENANT,
} from '@sidkik/global';
import { environment } from './environments/environment';
import { isSupported } from '@angular/fire/analytics';

declare global {
  var logger: Logger;
}

// to enable logging - localStorage.setItem('ROARR_LOG', 'true')
const loggerEnabled = (globalThis as any).localStorage.getItem('ROARR_LOG');
const loggerFilter = (globalThis as any).localStorage.getItem('ROARR_FILTER');
(globalThis as any).logger = (globalThis as any).log ?? {};
(globalThis as any).logger = createLogWriter(
  {
    logMethods: { ...createLogMethods() },
  },
  loggerEnabled,
  loggerFilter
);

logger.info('main', 'starting app');

if (!environment.emulator) {
  enableProdMode();
}

// get the hostname to load the external config
let hostname = window.location.hostname;
if (hostname === 'localhost') {
  hostname = 'admin-local.sidkik.app';
}

const platformEnabledAccounts = [
  'tn_plqr9jb8bbdo',
  'tn_i8gjkakimbul',
  'fir-account-3f2bd',
];

async function fetchWithTimeout(resource: string, options: any = {}) {
  const { timeout = 15000 } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const resp = await fetch(resource, { ...options, signal: controller.signal });
  clearTimeout(id);
  return resp;
}

async function prepareApp() {
  if (environment.mock) {
    logger.info('main', 'environment is set to mock');
    const { worker } = await import('@sidkik/msw-mocks');
    return worker.start();
  }
  return Promise.resolve();
}
prepareApp().then(async () => {
  // load external config to be used in app module start
  return fetchWithTimeout(`https://bootstrap.sidkik.app/${hostname}`)
    .then(async (resp) => {
      const tenantAppConfig = (await resp.json()) as AppConfig;
      let supported = true;
      try {
        supported = await isSupported();
        logger.debug('main', 'analytics supported', supported);
      } catch (err) {
        logger.error('main', 'analytics not supported', err);
      }
      tenantAppConfig.telemetry = {
        trace: {
          enabled: true,
          serviceName: 'tenant-app',
        },
      };
      tenantAppConfig.emulator = environment.emulator;
      let isPlatformEnabled = platformEnabledAccounts.some(
        (account) => account === tenantAppConfig.firebase.tenantId
      );
      loader(tenantAppConfig, supported, isPlatformEnabled);
    })
    .catch((err) => {
      logger.fatal('main:bootstrap', 'issue fetching bootstrap', err);
      const errorTitle = `An error has occurred`;
      let errorMessage = `Failed to load app configuration: ${err.message}`;
      if (err.message.indexOf('user aborted')) {
        errorMessage =
          'Unable to load app configuration: Please try again in a few minutes.';
      }
      document.getElementsByClassName('sidkik-progress')[0].innerHTML =
        errorTitle;
      document.getElementsByClassName('sidkik-info')[0].innerHTML =
        errorMessage;
      const hostname = window.location.hostname;
      if (hostname === 'localhost') {
        // local load on fail
        const config: AppConfig = {
          api: {
            endpoint: 'http://localhost:18080/api',
          },
          emulator: true,
          chunkSize: 100000,
          devToolsInstrument: true,
          firebase: {
            apiKey: '123',
            authDomain: '',
            messagingSenderId: '',
            projectId: 'demo-account',
            storageBucket: 'demo-account.appspot.com',
          },
          images: {
            thumbnails: [
              { name: 'small', size: { height: 100, width: 100 } },
              { name: 'med', size: { height: 100, width: 100 } },
              { name: 'large', size: { height: 100, width: 100 } },
            ],
          },
          serviceWorkerEnabled: false,
          updateCheckInterval: 300000,
        };
        loader(config);
      }
    });
});

function loader(
  config: AppConfig,
  isSupported: boolean = true,
  isPlatformEnabled: boolean = false
) {
  logger.debug('main:loader', 'config', config);
  if (isPlatformEnabled) {
    logger.info('main:loader', 'enabling sidkik platform');
  }
  platformBrowserDynamic([
    { provide: APP_CONFIG, useValue: config },
    { provide: IS_PLATFORM_ENABLED, useValue: isPlatformEnabled },
    { provide: LOGGER_ENABLED, useValue: loggerEnabled },
    { provide: LOGGER_FILTER, useValue: loggerFilter },
    { provide: APP_CHECK_DEBUG_TOKEN, useValue: false },
    { provide: MOCK_ENABLED, useValue: environment.mock },
    { provide: SIDKIK_PROJECT, useValue: config.firebase.projectId },
    { provide: SIDKIK_TENANT, useValue: config.firebase.tenantId ?? '' },
    {
      provide: SIDKIK_AUTH_TENANT,
      useValue: config.firebase.authTenantId ?? '',
    },
    {
      provide: STRIPE_ENABLED,
      useValue: undefined,
    },
    {
      provide: BRANDING_CONFIG,
      useValue: {
        fullLogo: '/assets/logo.v1.svg',
        condensedLogo: '/assets/condensed-logo.v1.svg',
      } as BrandingConfig,
    },
    { provide: ANALYTICS_IS_SUPPORTED, useValue: isSupported },
    {
      provide: AUTHZ_CONFIG,
      useValue: {
        thirdPartyProvidersAllowed: false, // disable other logins than email
      } as AuthzConfig,
    },
  ])
    .bootstrapModule(AppModule)
    .catch((err) => {
      logger.error('main:loader', 'issue bootstrapping', err);
    });
}

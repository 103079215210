import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface SlackConfig {
  active: string;
  defaultChannel?: string;
}

export interface Channel {
  id: string;
  name: string;
}

export interface SlackAPI {
  getAuthURI(trace?: HTTPTraceHeader): Observable<string>;
  getConnected(trace?: HTTPTraceHeader): Observable<null>;
  getConfig(trace?: HTTPTraceHeader): Observable<SlackConfig>;
  sendTestMessage(trace?: HTTPTraceHeader): Observable<void>;
  getChannels(trace?: HTTPTraceHeader): Observable<Channel[]>;
  setChannels(
    defaultChannel: string,
    trace?: HTTPTraceHeader
  ): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class SlackService implements SlackAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  getChannels(trace?: HTTPTraceHeader): Observable<Channel[]> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminSlackGetChannels);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminSlackGetChannels
      );
      internalTrace = true;
    }
    return this.http
      .get<Channel[]>(
        `${this.tenantConfig.api.endpoint}/admin/slack/channels`,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackGetChannels, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackGetChannels);
        })
      );
  }

  sendTestMessage(trace?: HTTPTraceHeader): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminSlackSendTestMessage);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminSlackSendTestMessage
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(`${this.tenantConfig.api.endpoint}/admin/slack/test`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackSendTestMessage, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackSendTestMessage);
        })
      );
  }

  setChannels(
    defaultChannel: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminSlackSetChannels);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminSlackSetChannels
      );
      internalTrace = true;
    }
    return this.http
      .put<void>(
        `${this.tenantConfig.api.endpoint}/admin/slack/config`,
        {
          defaultChannel,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackSetChannels, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackSetChannels);
        })
      );
  }

  getAuthURI(trace?: HTTPTraceHeader): Observable<string> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminSlackGetAuthUri);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminSlackGetAuthUri
      );
      internalTrace = true;
    }
    return this.http
      .get<string>(`${this.tenantConfig.api.endpoint}/admin/slack/auth`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackGetAuthUri, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackGetAuthUri);
        })
      );
  }

  getConnected(trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminSlackGetConnected);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminSlackGetConnected
      );
      internalTrace = true;
    }
    return this.http
      .get<null>(`${this.tenantConfig.api.endpoint}/admin/slack/connected`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackGetConnected, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackGetConnected);
        })
      );
  }

  getConfig(trace?: HTTPTraceHeader): Observable<SlackConfig> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminSlackGetConfig);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminSlackGetConfig
      );
      internalTrace = true;
    }
    return this.http
      .get<SlackConfig>(
        `${this.tenantConfig.api.endpoint}/admin/slack/config`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackGetConfig, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminSlackGetConfig);
        })
      );
  }
}

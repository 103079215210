import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  InjectionToken,
  TemplateRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type TooltipData = { tip: string; delay?: number }; // | TemplateRef<void>;
export const TOOLTIP_DATA = new InjectionToken<TooltipData>(
  'Data to display in tooltip'
);

@Component({
  selector: 'sidkik-tooltip-container',
  templateUrl: './tooltip-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipContainerComponent {
  hideMe$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(@Inject(TOOLTIP_DATA) public tooltipData: TooltipData) {
    setTimeout(() => {
      this.hideMe$.next(false);
    }, this.tooltipData.delay ?? 0);
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { TimeSlot } from '@sidkik/global';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

interface TimeSpanForm {
  start: FormControl<string>;
  end: FormControl<string>;
}

@Component({
  selector: 'sidkik-time-span',
  templateUrl: './time-span.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimeSpanComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TimeSpanComponent,
      multi: true,
    },
  ],
})
export class TimeSpanComponent implements ControlValueAccessor, OnDestroy {
  @Input() disabled = false;
  value: TimeSlot | undefined;
  onChange: any;
  onTouch: any;
  formSet = false;
  form!: FormGroup<TimeSpanForm>;
  internalFormChangeSubscription!: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService
  ) { }

  ngOnDestroy(): void {
    this.internalFormChangeSubscription?.unsubscribe();
  }

  validate({ value }: FormControl) {
    return null;
  }

  setForm() {
    if (!this.formSet) {
      this.formSet = true;
      this.form = this.formBuilder.nonNullable.group({
        start: [this.value?.start ?? '', [Validators.required]],
        end: [this.value?.end ?? '', [Validators.required]],
      });
      this.internalFormChangeSubscription = this.form.valueChanges //.pipe(filter(() => this.acceptChanges === true))
        .subscribe((val) => {
          // convert values to TimeSlot
          // const timeSlot: TimeSlot = {
          //   start: val.start ?? '',
          //   end: val.end ?? '',
          // };
          // this.changed(timeSlot);
        });
    }
  }

  onBlur() {
    const val = this.form.value;
    // Convert start and end times to Date objects
    const startTime = new Date(`1970-01-01T${val.start}:00.000Z`).getTime();
    const endTime = new Date(`1970-01-01T${val.end}:00.000Z`).getTime();

    // If the end time is before the start time, reset the end time to the start time
    if (endTime < startTime) {
      val.end = val.start;
      this.form.patchValue({ end: val.start });
      setTimeout(() => {
        this.notificationService.showError(
          'Invalid End time',
          'End time cannot be before start time. Resetting to start time.'
        );
      });
    }
    const timeSlot: TimeSlot = {
      start: val.start ?? '',
      end: val.end ?? '',
    };
    this.changed(timeSlot);
  }

  writeValue(value: TimeSlot): void {
    this.value = value;
    this.setForm();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  changed(val: TimeSlot | undefined) {
    if (this.onTouch) this.onTouch();
    this.value = val;
    if (this.onChange) this.onChange(val);
  }
}

import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface WorkflowData {
  title: string;
  active: boolean;
  multipleEntries: boolean;
  source?: string;
  content?: string;
}

export interface WorkflowProperties extends StorageProperties {
  data: WorkflowData;
  meta: Meta;
  id: string;
}

export class Workflow extends Storage implements WorkflowProperties {
  public override data!: WorkflowData;

  constructor(options?: WorkflowProperties, user?: string) {
    super(options, user, EntityType.Workflow);
    this.update(options?.data);
  }

  public update(data?: WorkflowData): void {
    this.data = { ...data } as unknown as WorkflowData;
  }
}

/** Availability */
export const loadAvailabilitiesType =
  '[scheduling/availability] load availabilities';
export const loadAvailabilitiesAndSelectAvailabilityType =
  '[scheduling/availability] load availabilities and select availability';
export const loadAvailabilitiesSuccessType =
  '[scheduling/availability] load availabilities success';
export const loadAvailabilitiesAndSelectAvailabilitySuccessType =
  '[scheduling/availability] load availabilities and select availability success';
export const loadAvailabilitiesFailureType =
  '[scheduling/availability] load availabilities failure';
export const loadAvailabilitiesAndSelectAvailabilityFailureType =
  '[scheduling/availability] load availabilities and select availability failure';

export const selectAvailabilityType =
  '[scheduling/availability] select availability';

export const addAvailabilityType = '[scheduling/availability] add availability';
export const addAvailabilitySuccessType =
  '[scheduling/availability] add availability success';
export const addAvailabilityFailureType =
  '[scheduling/availability] add availability failure';

export const updateAvailabilityType =
  '[scheduling/availability] update availability';
export const updateAvailabilitySuccessType =
  '[scheduling/availability] update availability success';
export const updateAvailabilityFailureType =
  '[scheduling/availability] update availability failure';

export const loadBookingsType = '[scheduling/booking] load bookings';
export const loadBookingsAndSelectBookingType =
  '[scheduling/booking] load bookings and select booking';
export const loadBookingsSuccessType =
  '[scheduling/booking] load bookings success';
export const loadBookingsAndSelectBookingSuccessType =
  '[scheduling/booking] load bookings and select booking success';
export const loadBookingsFailureType =
  '[scheduling/booking] load bookings failure';
export const loadBookingsAndSelectBookingFailureType =
  '[scheduling/booking] load bookings and select booking failure';
export const selectBookingType = '[scheduling/booking] select booking';
export const addBookingType = '[scheduling/booking] add booking';
export const addBookingSuccessType = '[scheduling/booking] add booking success';
export const addBookingFailureType = '[scheduling/booking] add booking failure';
export const updateBookingType = '[scheduling/booking] update booking';
export const updateBookingSuccessType =
  '[scheduling/booking] update booking success';
export const updateBookingFailureType =
  '[scheduling/booking] update booking failure';

export const loadBookingChangeType =
  '[scheduling/booking-change] load booking-change';
export const loadBookingChangeSuccessType =
  '[scheduling/booking-change] load booking-change success';
export const loadBookingChangeFailureType =
  '[scheduling/booking-change] load booking-change failure';
export const selectBookingChangeType =
  '[scheduling/booking-change] select booking-change';
export const addBookingChangeType =
  '[scheduling/booking-change] add booking-change';
export const addBookingChangeSuccessType =
  '[scheduling/booking-change] add booking-change success';
export const addBookingChangeFailureType =
  '[scheduling/booking-change] add booking-change failure';
export const deleteBookingChangeType =
  '[scheduling/booking-change] delete booking-change';
export const deleteBookingChangeSuccessType =
  '[scheduling/booking-change] delete booking-change success';
export const deleteBookingChangeFailureType =
  '[scheduling/booking-change] delete booking-change failure';

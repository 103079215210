import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface SchedulingAPI {
  getAvailabilityByYearMonth(
    year: number,
    month: number,
    timezone: string,
    trace?: HTTPTraceHeader
  ): Observable<Availabilities>;

  getAvailability(trace?: HTTPTraceHeader): Observable<Availabilities>;

  getAvailabilityByProduct(
    productId: string,
    timezone: string,
    trace?: HTTPTraceHeader
  ): Observable<Availabilities>;

  updateAvailability(trace?: HTTPTraceHeader): Observable<void>;
}

export interface Availabilities {
  availabilities: Availability[];
  endDate: number;
  startDate: number;
  minHoursBeforeBooking: number;
}

export interface Availability {
  duration: number;
  productId: string;
  startTimeUnix: number;
  userId: string;
  userName: string;
  userAvatar: string;
}

@Injectable({
  providedIn: 'root',
})
export class SchedulingService implements SchedulingAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
      'Cache-Control': 'no-cache',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Cache-Control': 'no-cache',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  updateAvailability(trace?: HTTPTraceHeader): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminUpdateAvailability);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminUpdateAvailability
      );
      internalTrace = true;
    }
    return this.http
      .put<void>(
        `${this.tenantConfig.api.endpoint}/admin/scheduling/availability`,
        {},
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdateAvailability, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdateAvailability);
        })
      );
  }

  getAvailability(trace?: HTTPTraceHeader): Observable<Availabilities> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminGetAvailability);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminGetAvailability
      );
      internalTrace = true;
    }
    return this.http
      .get<Availabilities>(
        `${this.tenantConfig.api.endpoint}/admin/scheduling/availability`,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminGetAvailability, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminGetAvailability);
        })
      );
  }

  getAvailabilityByYearMonth(
    year: number,
    month: number,
    timezone: string,
    trace?: HTTPTraceHeader
  ): Observable<Availabilities> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminGetAvailabilityByYearMonth);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminGetAvailabilityByYearMonth
      );
      internalTrace = true;
    }
    return this.http
      .get<Availabilities>(
        `${this.tenantConfig.api.endpoint}/admin/scheduling/availability/${year}/${month}/${timezone}`,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.adminGetAvailabilityByYearMonth,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.adminGetAvailabilityByYearMonth
            );
        })
      );
  }

  getAvailabilityByProduct(
    productId: string,
    timezone: string,
    trace?: HTTPTraceHeader
  ): Observable<Availabilities> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.shopGetAvailabilityByProduct);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.shopGetAvailabilityByProduct
      );
      internalTrace = true;
    }
    return this.http
      .get<Availabilities>(
        `${this.tenantConfig.api.endpoint}/shop/availability/${
          this.tenantConfig.firebase.tenantId
        }/${productId}/${timezone}?cb=${Date.now()}`,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.shopGetAvailabilityByProduct,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.shopGetAvailabilityByProduct);
        })
      );
  }
}

@if (disabled){
<form>
  <div class="tw-flex tw-items-center tw-gap-2 tw-mt-1">
    <div class="input-wrapper-w-fit">
      <span class="input-prefix">Start</span>
      <input class="input-with-prefix-w-fit tw-min-w-32 tw-max-w-32 tw-bg-gray-100" disabled type="text" />
    </div>
    <div class="hidden sm:block tw-h-0.5 tw-bg-gray-400 tw-w-3"></div>
    <div class="input-wrapper-w-fit"><span class="input-prefix">End</span>
      <input class="input-with-prefix-w-fit tw-min-w-32 tw-max-w-32 tw-bg-gray-100" disabled type="text" />
    </div>
  </div>
</form>
} @else {
<form [formGroup]="form" name="form">
  <div class="tw-flex tw-items-center tw-gap-2">
    <div class="input-wrapper-w-fit">
      <span class="input-prefix">Start</span>
      <input class="input-with-prefix-w-fit tw-min-w-32" type="time" formControlName="start" (blur)="onBlur()" />
    </div>
    <div class="tw-h-0.5 tw-bg-gray-400 tw-w-3"></div>
    <div class="input-wrapper-w-fit"><span class="input-prefix">End</span>
      <input class="input-with-prefix-w-fit tw-min-w-32" type="time" formControlName="end" (blur)="onBlur()" />
    </div>
  </div>
</form>
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerComponent } from './components/container/container.component';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromLayout from './+state/layout.reducer';
import { LayoutEffects } from './+state/layout.effects';
import { LayoutFacade } from './+state/layout.facade';
import { UIModule } from '@sidkik/ui';
import { AuthzModule } from '@sidkik/authz';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { PureUserInfoComponent } from './components/user-info/pure-user-info.component';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { SidkikAPIModule } from '@sidkik/sidkik-api';
import { HelpComponent } from './components/help/help.component';

const pureComponents = [PureUserInfoComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(
      fromLayout.LAYOUT_FEATURE_KEY,
      fromLayout.layoutReducer
    ),
    EffectsModule.forFeature([LayoutEffects]),
    UIModule,
    AuthzModule,
    CdkScrollableModule,
    SidkikAPIModule,
  ],
  declarations: [ContainerComponent, UserInfoComponent, HelpComponent, ...pureComponents],
  exports: [ContainerComponent],
  providers: [LayoutFacade],
})
export class TenantLayoutModule {}

import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EmailLayout } from './email-layout';
import { ComboBoxOption, EntityType } from '@sidkik/global';
import { EmailWidget } from './email-widget';

export enum EmailType {
  DoubleOptIn = 'DoubleOptIn',
  Transactional = 'Transactional',
  Marketing = 'Marketing',
  EmailConfirmation = 'EmailConfirmation',
}

export const EmailTypeLookup: ComboBoxOption[] = [
  {
    value: EmailType.DoubleOptIn,
    label: 'Double Opt In',
    description:
      'Email requesting user to acknowledge opting in to the list. Add the Double Opt In button to your email. ',
  },
  {
    value: EmailType.Transactional,
    label: 'Transactional',
    description: 'Email critical to informing the user about account activity.',
  },
  {
    value: EmailType.Marketing,
    label: 'Marketing',
    description:
      'Email to promote a product or service. Requires opt out instructions. Automatically inserted by Sidkik.',
  },
  {
    value: EmailType.EmailConfirmation,
    label: 'Email Confirmation',
    description:
      "Email to confirm the user's email address. Add the Email Confirmation button to your email. ",
  },
];

export interface EmailData {
  title: string;
  fallbackSubject?: string;
  emailType: EmailType;
  content?: EmailLayout;
  personalizations?: string[];
  widgets?: EmailWidget[];
  trackOpens?: boolean;
  trackClicks?: boolean;
  redirectPage?: string;
}

export interface EmailProperties extends StorageProperties {
  data: EmailData;
  meta: Meta;
  id: string;
}

export class Email extends Storage implements EmailProperties {
  public override data!: EmailData;

  constructor(options?: EmailProperties, user?: string) {
    super(options, user, EntityType.Email);
    this.update(options?.data);
  }

  public update(data?: EmailData): void {
    this.data = { ...data } as unknown as EmailData;
    this.data.content = this.data.content || {};
  }
}

import { Asset } from '.';
import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';
import { FlowSection } from './flow-section';

export interface FlowData {
  title: string;
  image?: Asset;
  video?: Asset;
  tags?: string[];
  synopsis: string;
  sections: FlowSection[];
  ctaText?: string;
  description?: string;
  active?: boolean;
}

export interface FlowProperties extends StorageProperties {
  data: FlowData;
  meta: Meta;
  id: string;
}

export class Flow extends Storage implements FlowProperties {
  public override data!: FlowData;

  constructor(options?: FlowProperties, user?: string) {
    super(options, user, EntityType.Flow);
    this.update(options?.data);
  }

  public update(data?: FlowData): void {
    const {
      title = '',
      image,
      video,
      tags = [],
      synopsis = '',
      sections = [],
      description = '',
      ctaText = '',
      active = true,
    } = data || {};
    this.data.title = title;
    this.data.description = description;
    this.data.ctaText = ctaText;
    this.data.image = image;
    this.data.video = video;
    this.data.tags = tags;
    this.data.active = active;
    this.data.synopsis = synopsis;
    this.data.sections = sections;
  }
}

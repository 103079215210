export const loadSettingsType =
  '[tenant-settings/configuration] load configurations';
export const loadSettingsSuccessType =
  '[tenant-settings/configuration] load configurations success';
export const loadSettingsFailureType =
  '[tenant-settings/configuration] load configurations failure';

export const updateNetworkingType =
  '[tenant-settings/configuration] update networking';
export const updateNetworkingSuccessType =
  '[tenant-settings/configuration] update networking success';
export const updateNetworkingFailureType =
  '[tenant-settings/configuration] update networking failure';

export const updateAddonType = '[tenant-settings/configuration] update addon';
export const updateAddonSuccessType =
  '[tenant-settings/configuration] update addon success';
export const updateAddonFailureType =
  '[tenant-settings/configuration] update addon failure';

export const updateThemeType = '[tenant-settings/configuration] update theme';
export const updateThemeSuccessType =
  '[tenant-settings/configuration] update theme success';
export const updateThemeFailureType =
  '[tenant-settings/configuration] update theme failure';

export const updateCommunityType =
  '[tenant-settings/configuration] update community';
export const updateCommunitySuccessType =
  '[tenant-settings/configuration] update community success';
export const updateCommunityFailureType =
  '[tenant-settings/configuration] update community failure';

export const updateAccountingType =
  '[tenant-settings/configuration] update accounting';
export const updateAccountingSuccessType =
  '[tenant-settings/configuration] update accounting success';
export const updateAccountingFailureType =
  '[tenant-settings/configuration] update accounting failure';

export const updateSchedulingType =
  '[tenant-settings/configuration] update scheduling';
export const updateSchedulingSuccessType =
  '[tenant-settings/configuration] update scheduling success';
export const updateSchedulingFailureType =
  '[tenant-settings/configuration] update scheduling failure';

import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export enum DomainVerificationStatus {
  INITIAL = 'initial',
  PENDING = 'pending',
  VERIFIED = 'verified',
  ACTIVE = 'active',
  FAILED = 'failed',
}
export interface DomainVerification {
  dnsVerified: boolean;
  verificationCname: string;
  verificationData: string;
  verificationError: string;
  verificationStatus: string;
}
export interface NetworkingConfigData {
  customDomain?: string;
  subDomain?: string;
  domainVerification?: DomainVerification;
}

export interface NetworkingConfigProperties extends StorageProperties {
  data: NetworkingConfigData;
  meta: Meta;
  id: string;
}

export class NetworkingConfig
  extends Storage
  implements NetworkingConfigProperties
{
  public override data!: NetworkingConfigData;

  constructor(options?: NetworkingConfigProperties, user?: string) {
    super(options, user, EntityType.NetworkingConfiguration);
    this.id = 'networking';
    if (options?.data) this.update(options?.data);
  }

  public update(data: NetworkingConfigData): void {
    this.data = { ...this.data, ...data };
  }
}

import { createAction, props } from '@ngrx/store';
import { StripeAccount, noOperation } from '@sidkik/global';
import { ZoomConnected } from '@sidkik/sidkik-api';

export const loadStripeAccount = createAction(
  '[tenant-layout] load stripe account'
);

export const noop = createAction(noOperation);

export const loadStripeAccountSuccess = createAction(
  '[tenant-layout] load stripe account success',
  props<{ stripe: StripeAccount }>()
);

export const loadStripeAccountFailure = createAction(
  '[tenant-layout] load stripe account failure',
  props<{ error: any }>()
);

export const loadZoomConnection = createAction(
  '[tenant-layout] load zoom connection'
);

export const loadZoomConnectionSuccess = createAction(
  '[tenant-layout] load zoom connection success',
  props<{ zoom: ZoomConnected }>()
);

export const loadZoomConnectionFailure = createAction(
  '[tenant-layout] load zoom connection failure',
  props<{ error: any }>()
);

import { Pipe, PipeTransform } from '@angular/core';
import { OperatorLiterals, Operators } from '@sidkik/global';

@Pipe({
  name: 'operatorLabel',
})
export class OperatorLabelPipe implements PipeTransform {
  transform(operator: string): string {
    const operatorObj = Operators.find((op) => op.value === operator);
    switch (operatorObj?.value) {
      case OperatorLiterals.False:
        return 'No';
      case OperatorLiterals.True:
        return 'Yes';
      case OperatorLiterals.StringDoesNotExist:
        return 'Does not exist';
      case OperatorLiterals.StringExists:
        return 'Exists';
    }
    return operatorObj ? operatorObj.label : '';
  }
}

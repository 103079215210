<div class="tw-flex tw-border-t tw-border-gray-200 tw-p-4 tw-items-center">
  <div class="tw-group tw-block tw-flex-1 tw-pointer-events-none tw-select-none tw-w-[10rem]">
    <div class="tw-flex tw-items-center">
      <div>
        <sidkik-avatar [imageUrl]="userPhoto ?? ''"></sidkik-avatar>
      </div>
      <div class="tw-ml-3  tw-w-40">
        <p class="tw-text-sm tw-font-medium tw-text-gray-700 group-hover:tw-text-gray-900 tw-line-clamp-1">{{userName}}
        </p>
        <p class="tw-text-xs tw-font-medium tw-text-gray-500 group-hover:tw-text-gray-700 tw-line-clamp-1">{{userEmail}}
        </p>
      </div>
    </div>
  </div>
  <div class="tw-group tw-block">
    <button type="button" [sidkikDropdownTrigger]="userinfo"
      class="tw-flex tw-items-center tw-rounded-full tw-bg-gray-100 tw-text-gray-400 hover:tw-text-gray-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-100"
      id="menu-button" aria-expanded="true" aria-haspopup="true">
      <span class="tw-sr-only">Open options</span>
      <!-- Heroicon name: mini/ellipsis-vertical -->
      <svg class="tw-h-5 tw-w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        aria-hidden="true">
        <path
          d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
      </svg>
    </button>
    <sidkik-dropdown #userinfo>
      <sidkik-dropdown-item [routerLink]="['/profile']">Profile</sidkik-dropdown-item>
      <sidkik-dropdown-item [dividerTop]="true" [routerLink]="['/auth/logout']">Sign out</sidkik-dropdown-item>
    </sidkik-dropdown>
  </div>
</div>
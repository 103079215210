import { StorageProperties, Storage } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';
import {
  GeneralAvailabilityDayOfWeek,
  OutOfOfficePeriod,
  AvailabilityOverrideDate,
  TemporaryUnavailabilityRecurring,
} from './common';

export interface UserAvailabilityGlobalData {
  active?: boolean;
  generalAvailabilityDayOfWeek?: GeneralAvailabilityDayOfWeek[];
  availabilityOverrideDate?: AvailabilityOverrideDate[];
  outOfOffice?: OutOfOfficePeriod[];
  temporaryUnavailabilityRecurring?: TemporaryUnavailabilityRecurring[];
  timezone?: string;
  userId: string;
}

export interface UserAvailabilityGlobalProperties extends StorageProperties {
  data: UserAvailabilityGlobalData;
  meta: Meta;
  id: string;
}

export class UserAvailabilityGlobal
  extends Storage
  implements UserAvailabilityGlobalProperties
{
  public override data!: UserAvailabilityGlobalData;

  constructor(options?: UserAvailabilityGlobalProperties, user?: string) {
    super(options, user, EntityType.UserAvailabilityGlobal);
    this.update(options?.data);
  }

  public update(data?: UserAvailabilityGlobalData): void {
    this.data = {
      ...(this.data ?? {}),
      ...data,
    } as unknown as UserAvailabilityGlobalData;
  }
}

import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export enum CampaignRunState {
  Pending = 'pending',
  Scheduled = 'scheduled',
  Running = 'running',
  Completed = 'completed',
  Errored = 'errored',
  Cancelled = 'cancelled',
}

export interface CampaignStats {
  queued: number;
  delivered: number;
  opened: number;
  clicked: number;
  bounced: number;
  spam: number;
  dropped: number;
  processed: number;
}

export interface CampaignRunData {
  campaign: string;
  lists: string[];
  subject?: string;
  sendAt: number;
  email: string;
  state?: CampaignRunState;
  stats?: CampaignStats;
}

export interface CampaignRunProperties extends StorageProperties {
  data: CampaignRunData;
  meta: Meta;
  id: string;
}

export class CampaignRun extends Storage implements CampaignRunProperties {
  public override data!: CampaignRunData;

  constructor(options?: CampaignRunProperties, user?: string) {
    super(options, user, EntityType.CampaignRun);
    this.update(options?.data);
  }

  public update(data?: CampaignRunData): void {
    this.data = { ...data } as unknown as CampaignRunData;
  }
}

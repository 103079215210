import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface CustomerTagData {
  name: string;
  description: string;
  customerCount?: number;
}

export interface CustomerTagProperties extends StorageProperties {
  data: CustomerTagData;
  meta: Meta;
  id: string;
}

export class CustomerTag extends Storage implements CustomerTagProperties {
  public override data!: CustomerTagData;

  constructor(options?: CustomerTagProperties, user?: string) {
    super(options, user, EntityType.CustomerTag);
    this.update(options?.data);
  }

  public update(data?: CustomerTagData): void {
    this.data = { ...data } as unknown as CustomerTagData;
  }

  public incrementCustomerCount(incr: number): void {
    this.data.customerCount = (this.data.customerCount || 0) + incr;
  }
}
